import React, { useEffect, useRef, useState } from "react";
import { ensureAuthentication } from "./rtm";
import { RtmClient } from "@zexcore/rtm-client";

export default function useRtmSubscription(
  eventName: string,
  callback?: (...data: any | any[]) => void
) {
  const [lastMessage, setLastMessage] = useState<any>();

  const unsub = useRef<any>(null);

  async function setuo() {
    const cli: RtmClient = await ensureAuthentication();
    const unsu = cli!.Subscribe(eventName, (data: any) => {
      if (data) setLastMessage(data);
      callback?.(data);
    });
    unsub.current = unsu;
  }

  useEffect(() => {
    setuo();

    return () => {
      unsub.current?.();
    };
  }, []);

  return [lastMessage];
}
