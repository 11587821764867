import React, { ReactElement } from "react";
import { Button, Divider, Link, Paper, Stack, Typography } from "@mui/material";

/**
 * Just like the insights card on dashboard, but more simplified.
 * @param props
 * @returns
 */
export default function SimpleInsightCard(props: {
  title?: string;
  value?: string;
  subheading?: any;
  accent?: string;
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: "120px",
        minWidth: "350px",
        background: "#101828",
        borderTop: "4px solid " + props.accent,
        borderRadius: "12px",
        p: "24px",
      }}
    >
      <Stack spacing="8px">
        <Stack direction={"row"} alignItems={"center"} spacing="12px">
          <Typography fontSize={14} fontWeight={500} color="#94969C">
            {props.title}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing="16px" alignItems={"center"}>
          <Typography fontSize={36} fontWeight={600}>
            {props.value}
          </Typography>
          <Typography fontSize={16} fontWeight={400} color="#85888E">
            {props.subheading}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
