import { Stack } from "@mui/material";
import React from "react";

export default function IconContainer(props: any) {
  return (
    <Stack
      sx={{
        width: "48px",
        height: "48px",
        borderRight: "3px solid #333741",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {props.children}
    </Stack>
  );
}
