import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_ADD_USER, IC_ALERT_WARNING, IC_USER_MINUS } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import PFMCheckbox from "../components/PFMCheckbox";
import moment from "moment";
import { User } from "@zexcore/types";

export default function SuspendedUserDialog(props: DialogProps<User>) {
  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USER_MINUS />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Suspended User
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Information about suspended user.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Email
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Role
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.role}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.comments || "N/A"}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Reasons
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.suspensionReason || "N/A"}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Activity
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Last modified
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(
                    props.data?.updatedTimestamp! ||
                      props.data?.registeredTimestamp!
                  )
                  .format("MMM DD, YYYY HH:mm:ss")}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.addedByUid ? "Added on" : "Registered on"}
              </Typography>
              <Typography fontSize={14} color="#94969C">
                {moment
                  .unix(props.data?.registeredTimestamp!)
                  .format("MMM DD, YYYY HH:mm:ss")}
              </Typography>
            </Stack>
            {props.data?.addedByUid && (
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ minWidth: "150px" }}
                >
                  Added by
                </Typography>
                <Tooltip title={props.data.addedByUid}>
                  <Typography fontSize={14} color="#94969C">
                    {props.data.addedByName}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
            <Stack
              direction={"row"}
              sx={{ width: "100%" }}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Suspended on
              </Typography>
              <Tooltip title={props.data?.suspendedTimestamp}>
                <Typography fontSize={14} color="#94969C">
                  {moment
                    .unix(props.data?.suspendedTimestamp!)
                    .format("MMM DD, YYYY HH:mm:ss")}
                </Typography>
              </Tooltip>
            </Stack>
            <Alert
              variant="filled"
              color="info"
              icon={<IC_ALERT_WARNING stroke="#079455" fill="transparent" />}
            >
              <AlertTitle>Info</AlertTitle>
              <Typography>
                The user was suspended and can no longer interact with the
                platform. If you wish to give this user another chance, please
                consider deleting the internal records using special access.
              </Typography>
            </Alert>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
