import { RtmClient } from "@zexcore/rtm-client";
import { AppConfig } from "../config";
import { FbAuth } from "../authentication/firebase";
import {
  APIKey,
  LogMessage,
  LogMessageKind,
  PaginatedResponse,
  Project,
  User,
} from "@zexcore/types";

let client: RtmClient;

export async function ensureAuthentication(): Promise<RtmClient> {
  return new Promise(async (resolve, reject) => {
    if (!client) {
      const token = await FbAuth.currentUser?.getIdToken();
      client = new RtmClient(AppConfig.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: token,
        onMessage(raw) {},
        async onOpen() {
          console.log("Connected to the RTM server.");
          resolve(client);
        },

        onClose() {
          console.log("Connection with the RTM server was closed.");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(client);
    }
  });
}

/**
 * Subscribe to an event called e and run a function on the data it sent.
 */
export async function rtmSubscribeEvent(e: string, ...data: any[]) {}

/**
 * Subscribes to the log messages, and invokes the callback if the filter matches.
 * @param callback
 * @param filter
 */
export function rtmSubscribeLog(callback: (log: LogMessage) => void) {
  return client.Subscribe("onLogMessage", (...data: any[]) => {
    // If the filter matches, we call-back
    const logMsg = data[0] as LogMessage;
    callback(logMsg);
  }) as any;
}

// Common Functions that are supported by both user roles

export async function rtmGetProfile() {
  await ensureAuthentication();
  const prof = await client.CallWait<User>("rtmGetProfile");
  return prof;
}

// Admin only functions

/**
 * Lists all the users on the platform, sorted by registeration time in descending. Requires administrator role.
 * @param pageNumber
 * @returns
 */
export async function rtmListUsers(pageNumber: number) {
  await ensureAuthentication();
  const users = await client.CallWait<PaginatedResponse<User>>(
    "rtmListUsers",
    pageNumber
  );
  return users;
}

/**
 * Creates a new user with specified details.
 * @returns
 */
export async function rtmAddUser(user: Partial<User>) {
  await ensureAuthentication();
  const newuser = await client.CallWait<User & { password: string }>(
    "rtmAddUser",
    user
  );
  return newuser;
}

// User only functions

/**
 * Returns the list of user's current projects.
 * @returns
 */
export async function rtmGetProjects() {
  await ensureAuthentication();
  const prof = await client.CallWait<Project[]>("rtmGetProjects");
  return prof;
}

/**
 * Creates a new project and returns it
 * @returns
 */
export async function rtmCreateProject(proj: Partial<Project>) {
  await ensureAuthentication();
  const prof = await client.CallWait<Project>("rtmCreateProject", proj);
  return prof;
}

/**
 * Updates the name and logo of the specified project.
 * @param proj
 * @returns
 */
export async function rtmUpdateProject(proj: Partial<Project>) {
  await ensureAuthentication();
  const prof = await client.CallWait<Project>("rtmUpdateProject", proj);
  return prof;
}

/**
 * Creates a new project and returns it
 * @returns
 */
export async function rtmCreateLogStreamWidget(
  proj: Partial<Project>,
  widgetData: { title: string; type: LogMessageKind | "*"; tags: string[] }
) {
  await ensureAuthentication();
  const prof = await client.CallWait<Project>(
    "rtmCreateLogStreamWidget",
    proj.id,
    widgetData
  );
  return prof;
}

/**
 * Creates a new project and returns it
 * @returns
 */
export async function rtmCreateEventStreamWidget(
  proj: Partial<Project>,
  widgetData: { title: string; names: string[] }
) {
  await ensureAuthentication();
  const prof = await client.CallWait<Project>(
    "rtmCreateEventStreamWidget",
    proj.id,
    widgetData
  );
  return prof;
}

export async function rtmRemoveWidget(projectId: string, widgetIndex: number) {
  await ensureAuthentication();
  const prof = await client.CallWait<Project>(
    "rtmRemoveWidget",
    projectId,
    widgetIndex
  );
  return prof;
}

/**
 * Creates a new API key and returns the original of the hash.
 * @param name
 * @returns
 */
export async function rtmCreateApiKey(name: string) {
  await ensureAuthentication();
  const key = await client.CallWait("rtmCreateApiKey", name);
  return key as APIKey;
}

/**
 * Gets all the API Keys of the user
 * @param name
 * @returns
 */
export async function rtmGetApiKeys() {
  await ensureAuthentication();
  const key = await client.CallWait("rtmGetApiKeys");
  return key as APIKey[];
}

/**
 * Deletes the specified key.
 * @param name
 * @returns
 */
export async function rtmDeleteApiKey(hash: string) {
  await ensureAuthentication();
  const key = await client.CallWait("rtmDeleteApiKey", hash);
  return key as boolean;
}

/**
 * Returns the old logs for a specified filter. Used by the widgets to show previous logs.
 * @param prop
 * @returns
 */
export async function rtmGetLogsForWidget(prop: {
  project: string;
  kind?: LogMessageKind;
  tags?: string[];
  skip?: number;
}) {
  await ensureAuthentication();
  const logs = await client.CallWait(
    "rtmGetLogsForWidget",
    prop.project,
    prop.kind,
    prop.tags,
    prop.skip
  );
  return logs as LogMessage[];
}

/**
 * Returns the old logs for a specified filter. Used by the widgets to show previous logs.
 * @param prop
 * @returns
 */
export async function rtmGetLogs(prop: {
  project: string;
  kind?: LogMessageKind;
  tags?: string[];
  pageNumber?: number;
}) {
  await ensureAuthentication();
  const logs = await client.CallWait(
    "rtmGetLogs",
    prop.project,
    prop.kind,
    prop.tags,
    prop.pageNumber
  );
  return logs as PaginatedResponse<LogMessage>;
}

/**
 * Returns the overview on events.
 * @param prop
 * @returns
 */
export async function rtmGetEvents(project: string) {
  await ensureAuthentication();
  const logs = await client.CallWait("rtmGetEvents", project);
  return logs as any;
}
