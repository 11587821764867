import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_HELP } from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import { Add } from "@mui/icons-material";
import { rtmCreateLogStreamWidget } from "../core/rtm";
import { useRecoilState } from "recoil";
import { aCurrentProject } from "../core/states";

export default function CreateLogsWidgetDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [groups, setGroups] = useState<AutocompleteOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<AutocompleteOption>();
  const [tags, setTags] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useRecoilState(aCurrentProject);

  const [busy, setBusy] = useState(false);

  async function addWidget() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a name for the widget.", {
          variant: "error",
        });
        return;
      }
      if (!selectedGroup) {
        enqueueSnackbar("Please select the type of the logs to stream.", {
          variant: "error",
        });
        return;
      }
      setBusy(true);

      // Update the project and add the widget.
      const p = await rtmCreateLogStreamWidget(selectedProject!, {
        tags: tags,
        title: name,
        type: selectedGroup.id,
      });

      if (p) {
        setSelectedProject(p);
        enqueueSnackbar("Widget added successfully.", { variant: "success" });
        props.closeHandler(true);
      }
    } catch (err: any) {
      enqueueSnackbar("Error adding a widget. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function loadMlGroups() {
    const oos: AutocompleteOption[] = [
      {
        label: "Everything",
        id: "*",
      },
      {
        label: "Informational",
        id: "information",
      },
      {
        label: "Warnings",
        id: "warning",
      },
      {
        label: "Errors",
        id: "error",
      },
      {
        label: "Crashes",
        id: "crash",
      },
    ];
    setGroups(oos);
  }

  useEffect(() => {
    loadMlGroups();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <Add />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Add Log Stream Widget
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Show logs from your project with specified criteria.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Title
              </Typography>
              <PFMInput
                text={name}
                disabled={busy}
                onUpdate={(t) => setName(t)}
                fullWidth
                placeholder="e.g. Unhandled errors"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                sx={{
                  maxWidth: "220px",
                  minWidth: "150px",
                }}
                spacing={"8px"}
              >
                <Typography className="required" fontSize={14} fontWeight={500}>
                  Log Type
                </Typography>
                <Tooltip title="Select the log type to filter. ">
                  <IC_HELP />
                </Tooltip>
              </Stack>
              <PFMAutoComplete
                important
                key={groups.length}
                options={groups}
                placeholder="Select logs type..."
                onUpdate={(sel) => setSelectedGroup(sel as any)}
                value={selectedGroup}
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                sx={{
                  maxWidth: "220px",
                  minWidth: "150px",
                }}
                spacing={"8px"}
              >
                <Typography fontSize={14} fontWeight={500}>
                  Includes Tags
                </Typography>
                <Tooltip title="Show logs that contains these tags only.">
                  <IC_HELP />
                </Tooltip>
              </Stack>
              <Autocomplete
                fullWidth
                multiple
                size="small"
                value={tags}
                onChange={(e, vs) => setTags(vs)}
                options={[]}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    {...(params as any)}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      hiddenLabel: true,
                      sx: { borderRadius: "8px" },
                    }}
                    hiddenLabel
                    helperText={"Enter the tags to filter the logs."}
                    placeholder={"Enter tags.."}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={addWidget}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Add Widget
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
