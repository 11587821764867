import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { IC_ADD_IMAGE, IC_SETTINGS } from "../assets";
import { Project } from "@zexcore/types";
import IconContainer from "../components/IconContainer";
import { ContentCopy } from "@mui/icons-material";
import PFMInput from "../components/PFMInput";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";
import { rtmUpdateProject } from "../core/rtm";
import { useRecoilState } from "recoil";
import { aCurrentProject } from "../core/states";

export default function ProjectSettingsDialog(props: DialogProps<Project>) {
  const project = props.data;

  const [busy, setBusy] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [name, setName] = useState<string>();
  const [, setSelectedProject] = useRecoilState(aCurrentProject);

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function save() {
    // Update project name and logo
    try {
      if (!name) {
        enqueueSnackbar("Please enter a project name.", { variant: "error" });
        return;
      }
      setBusy(true);
      const pro = await rtmUpdateProject({
        id: project?.id,
        name: name,
        image: uploadInfo,
      });
      if (pro) setSelectedProject(pro);
      // Updated
      props.closeHandler(true);
      enqueueSnackbar("Project updated successfully. ", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Error updating project. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  async function load() {
    try {
      setUploadInfo(project?.image);
      setName(project?.name);
    } catch (err: any) {}
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_SETTINGS fill="#FFFC" />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Project Settings
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                View or modify project details.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={"8px"} sx={{ px: "20px" }}>
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <Typography fontSize={18} fontWeight={600}>
                {project?.name}
              </Typography>
              <Tooltip title="Click to copy project id.">
                <Chip
                  variant="identifier"
                  label={project?.id}
                  icon={<ContentCopy />}
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(project?.id || "");
                    enqueueSnackbar("Project ID copied to clipboard.");
                  }}
                />
              </Tooltip>
            </Stack>
          </Stack>

          {/* <Stack justifyContent={"center"} alignItems={"center"}>
            <ButtonSwitch
              label="Basic"
              checkedLabel="Advanced"
              checked={advanced}
              onChange={(c) => setAdvanced(c)}
              style={{
                width: "200px",
              }}
            />
          </Stack> */}

          <Stack sx={{ px: "20px" }} spacing={"16px"}>
            {/* Basic project settings */}

            <PFMInput
              label="Project Name"
              text={name}
              onUpdate={(t) => setName(t)}
            />
            <input
              id="file-upload"
              type="file"
              style={{ opacity: 0, position: "absolute", zIndex: "-9999" }}
              onChange={(fe) => {
                uploadAttachment(fe.target?.files?.[0]!);
              }}
            />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "50px" }}
              >
                Logo
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                spacing="20px"
                alignItems={"center"}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: "64px", height: "64px" }}
                    src={uploadInfo}
                  >
                    <IC_ADD_IMAGE />
                  </Avatar>
                  {uploadState === "uploading" && (
                    <CircularProgress
                      variant="determinate"
                      value={uploadProgress}
                      size="68px"
                      sx={{ position: "absolute" }}
                    />
                  )}
                </div>
                <Stack
                  onClick={(c) => {
                    if (busy) return;
                    document.getElementById("file-upload")?.click();
                  }}
                  flex={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    background: "#FFFFFF13",
                    p: "16px",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600} color="#CECFD2">
                    Click to upload
                  </Typography>
                  <Typography fontSize={12} fontWeight={400} color="#94969C">
                    PNG, JPG
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              disabled={busy}
              onClick={save}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
