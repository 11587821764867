import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { FbAuth } from "../authentication/firebase";
import ZexcoreLogo from "./SidebarLogo";

export default function PageSplashScreen({ children }: any) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    FbAuth.authStateReady().then(() =>
      setTimeout(() => setInitialized(true), 1000)
    );
  }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack sx={{ height: "100vh" }}>
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <ZexcoreLogo
          style={{
            pointerEvents: "none",
          }}
        />
      </Stack>
    </Stack>
  );
}
