import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { IC_ADD_USER, IC_REFRESH } from "../../assets";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import AddUserDialog from "../../dialogs/AddUser";
import EditUserDialog from "../../dialogs/EditUser";
import SuspendedUserDialog from "../../dialogs/SuspendedUserDialog";
import { User } from "@zexcore/types";
import { rtmListUsers } from "../../core/rtm";

export default function SectionAdminUsers() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<User[]>([]);
  const { showModal } = useModal();

  async function loadUsers(page: number) {
    try {
      //Load data
      setBusy(true);
      const users = await rtmListUsers(page);
      if (users) {
        setTotalPages(users.pageCount);
        setPageNumber(users.currentPage);
        setTotalUsers(users.total);
        setData(users.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showAddUserDialog() {
    const modal = showModal(AddUserDialog, {
      closeHandler(result) {
        modal.destroy();
        loadUsers(pageNumber);
      },
    });
  }

  function showEditDialog(usr: User) {
    if (usr.suspended) {
      const modal = showModal(SuspendedUserDialog, {
        closeHandler(result) {
          modal.destroy();
          loadUsers(pageNumber);
        },
        data: usr,
      });
    } else {
      const modal = showModal(EditUserDialog, {
        closeHandler(result) {
          modal.destroy();
          loadUsers(pageNumber);
        },
        data: usr,
      });
    }
  }

  useEffect(() => {
    setSection(`Identity and Access Management`);
    setSectionDescription(
      `Manage users who has access to the platform with roles.`
    );

    // Load page 0
    loadUsers(0);
  }, []);

  function RenderUser(user: User) {
    return (
      <TableRow>
        <TableCell>
          <Typography color="#94969C">{user.uid}</Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={
                user.suspended
                  ? "Suspended"
                  : user.disabled
                  ? "Disabled"
                  : user.emailVerified
                  ? "Active"
                  : "Unverified"
              }
              variant="outlined"
              color={
                user.suspended
                  ? "error"
                  : user.disabled
                  ? "warning"
                  : user.emailVerified
                  ? "success"
                  : "info"
              }
              size="small"
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(user.registeredTimestamp)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {user.addedByUid ? user.addedByName : "Sign up"}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip
            label={user.role || "user"}
            variant="outlined"
            color="success"
            size="small"
          />
        </TableCell>
        <TableCell align="right">
          <Button onClick={() => showEditDialog(user)}>Edit</Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadUsers(pn)}
        prevPage={(pn) => loadUsers(pn)}
        titleBadge={totalUsers + " Users"}
        title="Users"
        description="List of all the users on the platform."
        actionButtons={
          <>
            <Button
              disabled={busy}
              onClick={() => loadUsers(pageNumber)}
              variant="contained"
              color="secondary"
              startIcon={<IC_REFRESH />}
            >
              Refresh
            </Button>
            <Button
              onClick={showAddUserDialog}
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={<IC_ADD_USER />}
            >
              Add User
            </Button>
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Registered</TableCell>
            <TableCell>Added by</TableCell>
            <TableCell>Role</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderUser(d))}</>}
      />
    </Stack>
  );
}
