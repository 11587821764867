import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { IC_BACK } from "../assets";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { useNavigate } from "react-router";
import { Role } from "@zexcore/types";

export default function SettingsSidebar() {
  const navigate = useNavigate();
  const [profile] = useRecoilState(aProfile);
  const [selectedItem, setSelectedItem] = useState<
    "general" | "admin" | "security"
  >("general");

  useEffect(() => {
    navigate("/settings/" + selectedItem);
  }, [selectedItem]);

  return (
    <Stack
      sx={{ width: "280px", px: "32px", py: "32px", background: "#101828" }}
      spacing="32px"
    >
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        sx={{ px: "18px", py: "18px" }}
      >
        <IconButton href="/dashboard">
          <IC_BACK />
        </IconButton>
        <Typography fontSize={24} fontWeight={400}>
          Settings
        </Typography>
      </Stack>
      <Divider />
      {/* Items  */}
      <Stack sx={{ px: "18px", py: "18px" }} spacing="8px">
        <Button
          onClick={() => setSelectedItem("general")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "general" ? "none" : undefined,
            background: selectedItem === "general" ? undefined : "transparent",
            color: selectedItem === "general" ? "#FCFCFD" : "#94969C",
          }}
          color="primary"
          variant="contained"
        >
          General
        </Button>

        {profile?.role === Role.Administrator && (
          <Button
            onClick={() => setSelectedItem("admin")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "admin" ? "none" : undefined,
              background: selectedItem === "admin" ? undefined : "transparent",
              color: selectedItem === "admin" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
          >
            Configuration
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
