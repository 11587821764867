import React from "react";
import { useEffect } from "react";
import { Stack, Typography, Divider } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../core/states";
import { Route, Routes, useNavigate } from "react-router";
import LoadingOverlay from "../components/LoadingOverlay";
import SettingsSidebar from "../components/SettingsSidebar";
import SectionSettingsGeneral from "../sections/settings/general";
import SectionSettingsSecurity from "../sections/settings/security";
import SectionSettingsAdmin from "../sections/settings/admin";

export default function PageSettings() {
  const [section] = useRecoilState(aCurrentSection);
  const [sectionInfo] = useRecoilState(aCurrentSectionDescription);

  return (
    <Stack sx={{ height: "100vh" }} direction={"row"}>
      <SettingsSidebar />
      <Stack flex={1} overflow={"auto"}>
        <Stack>
          <Stack sx={{ m: "32px" }}>
            <Typography fontSize={36} fontWeight={600}>
              {section || "Dashboard"}
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              {sectionInfo || "Welcome"}
            </Typography>
          </Stack>
          <Divider sx={{ mt: "16px" }} />
        </Stack>
        <Routes>
          <Route path="/" element={<SectionSettingsGeneral />} />
          <Route path="/general" element={<SectionSettingsGeneral />} />
          <Route path="/admin" element={<SectionSettingsAdmin />} />
          <Route path="/security" element={<SectionSettingsSecurity />} />
        </Routes>
      </Stack>
    </Stack>
  );
}
