import React, { useState } from "react";
import { useEffect } from "react";
import { MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { IC_HELP } from "../../assets";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";

export default function SectionSettingsAdmin() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [currency, setCurrency] = useState<any>();
  const [busy, setBusy] = useState(false);

  const values = [{ label: "USD" }, { label: "EUR" }, { label: "GBP" }];

  async function saveConfig(key: string, value: string) {
    try {
      setBusy(true);
      // await RestSetConfig(key.toLowerCase(), value);
      // enqueueSnackbar("Configuration updated.");
      return true;
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please try again.", {
        variant: "error",
      });
    }
    setBusy(false);
    return false;
  }

  async function load() {
    try {
      // const cfgs = await RestGetConfigs();
      // console.log(cfgs);
      // const currencyCfg = cfgs.find((c) => c.key === "currency");
      // if (currencyCfg) setCurrency({ label: currencyCfg.value.toUpperCase() });
    } catch (err: any) {
      enqueueSnackbar("Error loading configuration. Please try again.", {
        variant: "error",
      });
    }
  }

  async function updateCurrency(value: any) {
    if (await saveConfig("currency", value.label)) setCurrency(value);
  }

  useEffect(() => {
    setSection(`Configuration`);
    setSectionDescription(`View and modify platform configuration.`);
    load();
  }, []);

  return (
    <Stack sx={{ p: "40px" }} alignItems={"start"} spacing="8px">
      <Stack direction={"row"} alignItems={"center"} spacing="8px">
        <Typography>Invoice Currency</Typography>
        <Tooltip
          arrow
          placement="right"
          title="The currency to use for customer invoices. Enforces customers to pay in a specific currency. Defaults to USD."
        >
          <IC_HELP />
        </Tooltip>
      </Stack>
      <Stack direction={"row"} sx={{ width: "300px" }}>
        <PFMAutoComplete
          important
          options={values}
          key={currency}
          onUpdate={(o: any) => updateCurrency(o)}
          value={currency}
        />
      </Stack>
    </Stack>
  );
}
