import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import { IC_EMAIL, IC_PASSWORD } from "../../assets";
import { useNavigate } from "react-router";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import ZexcoreLogo from "../../components/SidebarLogo";

export default function PageLogin() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  async function signin(fe: any) {
    fe?.preventDefault?.();
    setError("");
    setBusy(false);
    // Make sure all fields are valid...
    if (!email) {
      setError("email");
      return;
    }
    if (!password) {
      setError("password");
      return;
    }
    try {
      setBusy(true);
      // Sign in using email and pass
      const r = await signInWithEmailAndPassword(FbAuth, email, password);
      if (r && !r.user.emailVerified) {
        // Don't login.
        enqueueSnackbar(
          "Please confirm your account by clicking the link sent to your email.",
          { variant: "error" }
        );
        await signOut(FbAuth);
      } else {
        navigate("/dashboard");
      }
    } catch (err: any) {
      if (
        err.code &&
        (err.code === "auth/wrong-password" ||
          err.code === "auth/user-not-found" ||
          err.code === "auth/invalid-login-credentials")
      ) {
        enqueueSnackbar("Invalid username or password. Please try again.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error logging in. Please contact administrators.", {
          variant: "error",
        });
        console.log(err);
      }
    }
    setBusy(false);
  }

  useEffect(() => {
    if (FbAuth.currentUser) {
      // Navigate to /
      navigate("/dashboard");
    }
  }, []);

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay busy={busy} loadingText="Signing in, please wait..." />
      <ZexcoreLogo
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
        }}
      />
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
          <Typography
            fontSize={"30px"}
            fontWeight={"600"}
            sx={{ color: "#F5F5F6" }}
          >
            Sign in to your account
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
            Enter your details to sign in
          </Typography>
        </Stack>
        <FormGroup onSubmit={signin}>
          <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
            <PFMInput
              text={email}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              placeholder="Enter your email "
              startIcon={IC_EMAIL}
              errorText={error === "email" ? "Invalid email address." : ""}
            />
            <PFMInput
              password
              text={password}
              onUpdate={(t) => setPassword(t)}
              label="Password"
              important
              placeholder="Enter your password"
              startIcon={IC_PASSWORD}
              errorText={error === "password" ? "Invalid password." : ""}
              onReturn={signin}
            />
            <Stack spacing={"2px"}>
              <Link href="/recovery" underline="none">
                Forgot your password?
              </Link>
            </Stack>
            <Button
              onClick={signin}
              type="submit"
              sx={{ mt: "4px" }}
              variant="contained"
            >
              Sign in
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Don't have an account?
              </Typography>
              <Link fontSize={"14px"} href="/signup" underline="none">
                Sign up
              </Link>
            </Stack>
          </Stack>
        </FormGroup>
      </Stack>
    </Stack>
  );
}
