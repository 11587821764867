import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

export default function CreateProjectCard(props: { onClick?: () => void }) {
  return (
    <Paper
      sx={{
        transition: "all .3s",
        px: "20px",
        height: "150px",
        py: "16px",
        borderRadius: "12px",
        background: "#FFFFFF09",
        backdropFilter: "blur(10px)",
        ":hover": {
          background: "#FFFFFF0F",
          cursor: "pointer",
        },
      }}
      variant="outlined"
      onClick={props.onClick}
    >
      <Stack
        spacing={"16px"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100%" }}
      >
        <Add />
        <Typography>Create a Project</Typography>
      </Stack>
    </Paper>
  );
}
