import React, { useEffect } from "react";
import { useState } from "react";
import {
  Avatar,
  Button,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  IC_CUSTOMERS,
  IC_DASHBOARD,
  IC_PAYMENTS,
  IC_PRODUCTS,
  IC_SETTINGS,
  IC_SIGNOUT,
  IC_SUBSCRIPTIONS,
  IC_USERS,
} from "../assets";
import { signOut } from "firebase/auth";
import { FbAuth } from "../authentication/firebase";
import { useRecoilState } from "recoil";
import { aProfile } from "../core/states";
import { useLocation, useNavigate } from "react-router";
import { AppConfig } from "../config";
import { Role } from "@zexcore/types";
import {
  AdsClick,
  Event,
  EventBusy,
  KeyOffTwoTone,
  KeyOutlined,
  KeySharp,
  KeyTwoTone,
  PlayArrow,
  Terminal,
} from "@mui/icons-material";
import ZexcoreLogo from "./SidebarLogo";

export default function Sidebar() {
  const navigate = useNavigate();
  const [profile] = useRecoilState(aProfile);
  const loc = useLocation();
  const segs = loc.pathname.split("/");
  const [selectedItem, setSelectedItem] = useState<
    "dashboard" | "logs" | "users" | "settings" | "keys" | "events"
  >(segs && segs.length > 0 ? (segs[segs.length - 1] as any) : "dashboard");

  useEffect(() => {
    if (!selectedItem) return;
    // if the user is admin
    if (profile?.role === Role.Administrator) {
      // use /admin/ sub-route
      navigate("/admin/" + selectedItem);
    } else {
      navigate("/user/" + selectedItem);
    }
  }, [selectedItem]);

  return (
    <Stack
      sx={{
        width: "280px",
        px: "20px",
        py: "32px",
        m: "12px",
        background: "#FFFFFF13",
        "& .MuiButton-containedPrimary": {
          borderRadius: "50px",
        },
        "& .MuiButton-containedPrimary:hover": {
          background: "rgba(124,172,248,0.08)",
        },
        borderRadius: "12px",
      }}
      spacing="32px"
    >
      <ZexcoreLogo />
      {/* Items  */}
      <Stack sx={{ py: "16px" }} spacing="8px">
        <Button
          onClick={() => setSelectedItem("dashboard")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "dashboard" ? "none" : undefined,
            background:
              selectedItem === "dashboard" ? undefined : "transparent",
            color: selectedItem === "dashboard" ? "#FCFCFD" : "#94969C",
          }}
          color="primary"
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "dashboard" ? "#FCFCFD" : ""}>
              <IC_DASHBOARD />
            </SvgIcon>
          }
        >
          Dashboard
        </Button>

        {profile?.role === Role.User && (
          <>
            <Button
              onClick={() => setSelectedItem("events")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: selectedItem === "events" ? "none" : undefined,
                background:
                  selectedItem === "events" ? undefined : "transparent",
                color: selectedItem === "events" ? "#FCFCFD" : "#94969C",
              }}
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={selectedItem === "events" ? "#FCFCFD" : ""}>
                  <AdsClick />
                </SvgIcon>
              }
            >
              Events
            </Button>
            <Button
              onClick={() => setSelectedItem("logs")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: selectedItem === "logs" ? "none" : undefined,
                background: selectedItem === "logs" ? undefined : "transparent",
                color: selectedItem === "logs" ? "#FCFCFD" : "#94969C",
              }}
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={selectedItem === "logs" ? "#FCFCFD" : ""}>
                  <Terminal />
                </SvgIcon>
              }
            >
              Logs
            </Button>
            <Button
              onClick={() => setSelectedItem("keys")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: selectedItem === "keys" ? "none" : undefined,
                background: selectedItem === "keys" ? undefined : "transparent",
                color: selectedItem === "keys" ? "#FCFCFD" : "#94969C",
              }}
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={selectedItem === "keys" ? "#FCFCFD" : ""}>
                  <KeyOutlined />
                </SvgIcon>
              }
            >
              API Keys
            </Button>
          </>
        )}

        {profile?.role === Role.Administrator && (
          <Button
            onClick={() => setSelectedItem("users")}
            disableElevation
            sx={{
              borderRadius: "8px",
              justifyContent: "start",
              alignItems: "center",
              pointerEvents: selectedItem === "users" ? "none" : undefined,
              background: selectedItem === "users" ? undefined : "transparent",
              color: selectedItem === "users" ? "#FCFCFD" : "#94969C",
            }}
            variant="contained"
            startIcon={
              <SvgIcon htmlColor={selectedItem === "users" ? "#FCFCFD" : ""}>
                <IC_USERS />
              </SvgIcon>
            }
          >
            IAM Center
          </Button>
        )}
      </Stack>

      <Stack flex={1} />

      <Stack spacing={"24px"}>
        <Button
          href="/settings"
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "settings" ? "none" : undefined,
            background: selectedItem === "settings" ? undefined : "transparent",
            color: selectedItem === "settings" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "settings" ? "#FCFCFD" : ""}>
              <IC_SETTINGS />
            </SvgIcon>
          }
        >
          Settings
        </Button>
        <Stack direction={"row"} alignItems={"center"} spacing="12px">
          <Avatar
            sx={{ width: "40px", height: "40px", color: "white" }}
            src={FbAuth.currentUser?.photoURL || ""}
          />
          <Stack flex={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#CECFD2",
                maxWidth: "160px",
                wordWrap: "unset",
                overflowWrap: "anywhere",
              }}
            >
              {profile?.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#94969C",
                wordBreak: "break-word",
              }}
            >
              {profile?.email}
            </Typography>
          </Stack>
          <IconButton onClick={() => signOut(FbAuth)} title="Sign out">
            <IC_SIGNOUT />
          </IconButton>
        </Stack>
      </Stack>

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontSize={10}>{AppConfig.version}</Typography>
        <Typography fontSize={10}>{AppConfig.build}</Typography>
      </Stack>
    </Stack>
  );
}
