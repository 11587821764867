import React, { useEffect, useState } from "react";
import { Button, FormGroup, Link, Stack, Typography } from "@mui/material";
import PFMInput from "../../components/PFMInput";
import PFMCheckbox from "../../components/PFMCheckbox";
import { IC_EMAIL, IC_NAME, IC_PASSWORD } from "../../assets";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useModal } from "mui-modal-provider";
import { RestPostSignup } from "../../core/rest";
import ZexcoreLogo from "../../components/SidebarLogo";
import { Key, KeyOutlined } from "@mui/icons-material";

export default function PageSignup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [license, setLicense] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [created, setCreated] = useState(false);
  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { showModal } = useModal();

  async function signup(fe: any) {
    fe?.preventDefault?.();
    setError("");
    // Make sure all fields are valid...
    if (!name) {
      setError("name");
      return;
    }
    if (!license) {
      setError("license");
      return;
    }
    if (!email) {
      setError("email");
      return;
    }
    if (!password || !passwordHasEnoughCharacters || !passwordHasSymbol) {
      setError("password");
      return;
    }

    await continueSignup();
  }

  async function continueSignup() {
    setBusy(false);
    try {
      setBusy(true);
      await RestPostSignup(name, email, password, license);
      setCreated(true);
      enqueueSnackbar("Please check your email to confirm your account.");
    } catch (err: any) {
      console.log(err);
      if (err.response.status === 409) {
        enqueueSnackbar("Email is already registered with another account.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error: " + err.response.data, {
          variant: "error",
        });
      }
    }
    setBusy(false);
  }

  useEffect(() => {
    if (FbAuth.currentUser) {
      // Navigate to /
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay
        busy={busy}
        loadingText="Creating account, please wait..."
      />
      <ZexcoreLogo
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
        }}
      />
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
          <Typography
            fontSize={"30px"}
            fontWeight={"600"}
            sx={{ color: "#F5F5F6" }}
          >
            Create Your Account
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
            Ready to get started? Create your account now.
          </Typography>
        </Stack>
        <FormGroup>
          <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
            <PFMInput
              text={name}
              label="Name"
              errorText={error === "name" ? "Please enter your name." : ""}
              onUpdate={(t) => setName(t)}
              important
              placeholder="Enter your full name"
              startIcon={IC_NAME}
            />
            <PFMInput
              text={email}
              errorText={error === "email" ? "Please enter your email." : ""}
              onUpdate={(t) => setEmail(t)}
              label="Email"
              important
              placeholder="Enter your email"
              startIcon={IC_EMAIL}
            />

            <PFMInput
              text={license}
              errorText={
                error === "license" ? "Please enter a valid license key." : ""
              }
              onUpdate={(t) => setLicense(t)}
              label="License Key"
              important
              placeholder="Enter your license key"
              startIcon={Key}
              onReturn={signup}
            />

            <PFMInput
              text={password}
              errorText={
                error === "password" ? "Please enter a valid password." : ""
              }
              onUpdate={(t) => setPassword(t)}
              label="Password"
              important
              password
              placeholder="Create a password"
              startIcon={IC_PASSWORD}
              onReturn={signup}
            />

            <Stack spacing={"2px"}>
              <PFMCheckbox
                value={passwordHasEnoughCharacters}
                label="Must be at least 8 characters"
              />
              <PFMCheckbox
                value={passwordHasSymbol}
                label="Must contain one special character"
              />
            </Stack>
            <Button sx={{ mt: "4px" }} onClick={signup} variant="contained">
              Sign up
            </Button>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "12px" }}
              spacing="8px"
            >
              <Typography color="#94969C" fontSize={"14px"}>
                Already have an account?
              </Typography>
              <Link href="/login" underline="none" fontSize={"14px"}>
                Login
              </Link>
            </Stack>
          </Stack>
        </FormGroup>
      </Stack>
    </Stack>
  );
}
