import axios from "axios";
import { AppConfig } from "../config";

const BASE_URL = AppConfig.api;

/**
 * Called after a successful signup event. Sends an account confirmation email for the logged in user.
 */
export async function RestPostSignup(
  name: string,
  email: string,
  password: string,
  license: string
) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/signup", {
    name,
    email,
    password,
    license,
  });
}

/**
 * Called after a successful verification event. Sends a welcome email for the registered user.
 */
export async function RestPostVerification(email: string, stamp: number) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/confirmed", {
    stamp: stamp,
    email: email,
  });
}

/**
 * Sends a password reset link via email. Called from forgot password section.
 */
export async function RestRequestPasswordReset(email: string) {
  // Send the request
  await axios.post(BASE_URL + "/accounts/password-reset", {
    email: email,
  });
}
