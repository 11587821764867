import React, { useEffect, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { LOGO_ZEXCORE_LIGHT } from "../assets";

export default function ZexcoreLogo(props: {
  disableAnimation?: boolean;
  [key: string]: any;
}) {
  const [txt, setTxt] = useState("ZEXCORE");
  const [cursorOpacity, setCursorOpacity] = useState(0);

  const containerRef = useRef<any>(null);

  async function animateIn() {
    // Get all childs of container
    const elems = containerRef.current?.children || [];

    // Iterate all and set opacity to 1
    for (let x = 0; x < elems.length; x++) {
      elems[x].setAttribute("style", "opacity: 1;");
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    if (props.disableAnimation) return;
    await new Promise((resolve) => setTimeout(resolve, 100));
    setCursorOpacity(1);

    // Blink cursor
  }

  async function setup() {
    animateIn();
    if (props.disableAnimation) return;
    // Blink the cursor, indefinitely.
    while (true) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setCursorOpacity(0);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setCursorOpacity(1);
    }
  }

  useEffect(() => {
    setup();
  }, []);

  return (
    <Stack
      direction={"row"}
      alignItems={"baseline"}
      spacing={"8px"}
      justifyContent={"center"}
      {...props}
    >
      <img
        src={LOGO_ZEXCORE_LIGHT}
        alt="Zexcore Logo"
        style={{ height: "48px", objectFit: "contain" }}
      />
      <div ref={containerRef} style={{ display: "flex", flexDirection: "row" }}>
        {/* Iterate each word of logo, and  */}
        {Array.from(txt).map((t) => (
          <Typography
            fontFamily={"Unbounded"}
            fontSize={22}
            sx={{ opacity: 0, transition: "all .3s" }}
          >
            {t}
          </Typography>
        ))}
      </div>

      {!props.disableAnimation && (
        <Typography
          fontFamily={"Unbounded"}
          fontSize={32}
          sx={{ opacity: cursorOpacity, transition: "all .3s" }}
        >
          _
        </Typography>
      )}
    </Stack>
  );
}
