import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import { IC_EMAIL } from "../../assets";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../components/LoadingOverlay";
import { RestRequestPasswordReset } from "../../core/rest";
import ZexcoreLogo from "../../components/SidebarLogo";

export default function PageRecovery() {
  const [linkSent, setLinkSent] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);

  async function send() {
    try {
      if (!email) {
        setError("email");
        return;
      }
      setBusy(true);
      await RestRequestPasswordReset(email);
      enqueueSnackbar("Password reset email has been sent.");
      setLinkSent(true);
    } catch (err: any) {}
    setBusy(false);
  }

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay busy={busy} />
      <ZexcoreLogo
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
        }}
      />
      {/* Shown when the link has been sent */}
      {linkSent && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <SvgIcon sx={{ fontSize: "96px" }}>
              <IC_EMAIL />
            </SvgIcon>
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Check your email!
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              We've sent a verification link to {email}.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Typography color="#94969C" fontSize={"14px"}>
                  Didn't receive an email?
                </Typography>
                <Link
                  onClick={send}
                  underline="none"
                  sx={{ cursor: "pointer" }}
                  fontSize={"14px"}
                >
                  Resend
                </Link>
              </Stack>
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
      {/* Shown when the link has not yet been sent  */}
      {!linkSent && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Forgot password?
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              No worries. We will send you reset instructions.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <PFMInput
                text={email}
                onUpdate={(t) => setEmail(t)}
                errorText={
                  error === "email" ? "Please enter a valid email." : ""
                }
                label="Email"
                important
                placeholder="Enter your email "
                startIcon={IC_EMAIL}
                onReturn={send}
              />
              <Button onClick={send} sx={{ mt: "4px" }} variant="contained">
                Reset password
              </Button>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
    </Stack>
  );
}
