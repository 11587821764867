import React, { useEffect, useRef, useState } from "react";
import {
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { DashboardWidget, LogMessage, LogMessageKind } from "@zexcore/types";
import {
  ArrowRight,
  Close,
  ErrorOutline,
  InfoRounded,
  Settings,
  Warning,
} from "@mui/icons-material";
import { getColorByTag } from "../core/helper";
import { IC_SEARCH } from "../assets";
import { rtmRemoveWidget } from "../core/rtm";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { aCurrentProject } from "../core/states";
import useRtmSubscription from "../core/subscriptionHook";

export function LogStackTrace(props: { stack: string; sx?: SxProps }) {
  const [open, setOpen] = useState(false);
  return (
    <Stack
      onClick={() => setOpen(!open)}
      sx={{
        px: "32px",
        ...(props.sx || {}),
      }}
    >
      <Stack
        alignItems={"center"}
        direction={"row"}
        onClick={() => setOpen(!open)}
        sx={{
          ":hover": {
            color: "#4488FF",
            cursor: "pointer",
          },
        }}
      >
        <ArrowRight sx={{ rotate: open ? "90deg" : undefined }} />
        <Typography fontSize={"12px"}>Stack Trace</Typography>
      </Stack>
      {open && (
        <Typography fontSize={12} sx={{ mx: "8px" }}>
          {props.stack}
        </Typography>
      )}
    </Stack>
  );
}

export default function LogStreamWidget(props: {
  layout: DashboardWidget & { type: "logs"; source: { type: "log_messages" } };
  projectId: string;
  widgetIndex: number;
}) {
  const [showOptionMenu, setShowOptionMenu] = useState<any>(null);
  const [, setSelectedProject] = useRecoilState(aCurrentProject);
  const [refresh, setRefresh] = useState(0);
  const [logs, setLogs] = useState<(LogMessage & { count: number })[]>([]);
  const [autoScroll, setAutoScroll] = useState(true);
  const [lastMessage] = useRtmSubscription("onLogMessage");
  const containerRef = useRef<any>(null);

  async function remove() {
    try {
      const proj = await rtmRemoveWidget(props.projectId, props.widgetIndex);
      if (proj) await setSelectedProject(proj);
    } catch (err: any) {
      enqueueSnackbar("Error removing widget. Please try again.", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if (!lastMessage) return;
    // A new log msg
    const lm = lastMessage as LogMessage;
    if (lm.project !== props.projectId) return;
    //If this widget has tags, we filter the log by tags.
    if (props.layout.source.tags && props.layout.source.tags.length > 0) {
      // Make sure log has all the tags
      for (let t of props.layout.source.tags) {
        if (!lm.tags || lm.tags.includes(t) === false) return;
      }
    }
    // If KIND is set, make sure the log kind matches
    if (props.layout.source.kind && lm.kind !== props.layout.source.kind)
      return;

    // IF last message was same as this one, we only increase the count for last msg
    setRefresh(new Date().getTime());
    setLogs([...logs, lm as any]);
    if (autoScroll && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [lastMessage]);

  return (
    <Stack
      sx={{
        border: "1px solid #FFF2",
        borderRadius: "8px",
        px: "12px",
        py: "8px",
        height: "auto",
        minWidth: "45%",
        maxHeight: "100%",
        overflow: "hidden",
      }}
      flex={1}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing="8px">
          <Typography fontSize={16} fontWeight={600}>
            {props.layout.header || (props.layout as any).title}
          </Typography>
          <Chip
            onClick={() => setAutoScroll(!autoScroll)}
            size="small"
            label={"Toggle Auto Scroll"}
            color={autoScroll ? "success" : "default"}
          />
          <Chip
            variant="identifier"
            size="small"
            label={
              props.layout.source.kind ? props.layout.source.kind : "All Logs"
            }
            sx={{
              textTransform: "capitalize",
              color: getColorByTag(props.layout.source.kind || "*"),
            }}
          />
          {props.layout.source.tags?.map((t) => (
            <Chip
              variant="identifier"
              size="small"
              label={t}
              sx={{ color: getColorByTag(t) }}
            />
          ))}
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
          <div
            style={{
              height: 1,
              overflow: "visible",
              position: "relative",
            }}
          >
            <TextField
              variant="standard"
              size="small"
              placeholder="Search..."
              sx={{
                border: "1px solid #FFF1",
                borderRadius: "25px",
                alignItems: "center",
                justifyContent: "center",
                px: "12px",
                width: "110px",
                right: 0,
                top: -20,
                position: "absolute",
                height: "40px",
                transition: "all .4s",
                ":hover": {
                  width: "220px",
                  background: "#4488FFAA",
                },
                ":focus-within": {
                  width: "220px",
                  background: "#4488FFAA",
                },
              }}
              inputProps={{
                style: {
                  marginTop: "6px",
                },
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: <IC_SEARCH />,
              }}
            />
          </div>
          <IconButton onClick={(e) => setShowOptionMenu(e.currentTarget)}>
            <Settings />
          </IconButton>

          <Menu open={Boolean(showOptionMenu)} anchorEl={showOptionMenu}>
            <MenuItem
              onClick={() => {
                setShowOptionMenu(null);
                remove();
              }}
            >
              <ListItemIcon>
                <Close />
              </ListItemIcon>
              <ListItemText>Remove</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
      <Stack
        ref={containerRef}
        flex={1}
        sx={{
          background: "#FFF1",
          border: "1px solid #fff2",
          borderRadius: "6px",
          m: "8px",
          overflow: "auto",
        }}
      >
        {logs.map((l, i) => (
          <Stack
            sx={{
              background: "#4488FF09",
              borderRadius: "8px",
              m: "2px",
              cursor: "pointer",
              transition: "all .2s",
              gap: "8px",
              ":hover": {
                background: "#4488FF59",
              },
            }}
          >
            <Typography
              sx={{
                p: "4px",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "flex-start",
              }}
              display={"inline-block"}
              textAlign={"start"}
              alignItems={"center"}
            >
              {l.kind === LogMessageKind.Warning && (
                <Warning color="warning"></Warning>
              )}
              {l.kind === LogMessageKind.Information && (
                <InfoRounded color="info"></InfoRounded>
              )}
              {l.kind === LogMessageKind.Error && (
                <Warning color="error"></Warning>
              )}
              {l.kind === LogMessageKind.Crash && (
                <ErrorOutline color="error"></ErrorOutline>
              )}
              <span>
                {l?.message}
                <span
                  style={{
                    opacity: 0.5,
                    display: "inline-flex",
                    marginLeft: "8px",
                  }}
                >
                  {moment(new Date(l.created)).fromNow()}
                </span>{" "}
                {l.tags.map((t) => (
                  <Chip
                    size="small"
                    label={t}
                    variant="identifier"
                    sx={{ mx: "2px" }}
                  />
                ))}
              </span>
            </Typography>
            {l.stack && <LogStackTrace stack={l.stack} />}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
