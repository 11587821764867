import React, { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { Navigate } from "react-router";
import { FbAuth } from "../authentication/firebase";

/**
 * Renders children only if the user is logged in. Otherwise navigates to the /login (or specified redirectTo) page.
 * @returns
 */
export default function AuthenticatedRoute(props: {
  children: any;
  redirect: any;
}) {
  //const user = FbAuth.currentUser;
  const [user, setUser] = React.useState<any>();
  useEffect(() => {
    const unsub = getAuth().onAuthStateChanged((usr) => {
      if (usr) {
        // we are logged in
        setUser(usr);
      } else {
        setUser(null);
      }
    });
    return unsub;
  }, []);

  if (!FbAuth.currentUser) {
    return <Navigate to={props.redirect} />;
  }
  return <>{props.children}</>;
}
