import React from "react";
import { Avatar, Chip, Paper, Stack, Typography } from "@mui/material";
import { Project } from "@zexcore/types";
import moment from "moment";
import { useRecoilState } from "recoil";
import { aCurrentProject } from "../core/states";

export default function ProjectCard(props: { project: Project }) {
  const [, setSelectedProject] = useRecoilState(aCurrentProject);

  const proj = props.project;

  return (
    <Paper
      sx={{
        transition: "all .3s",
        px: "20px",
        py: "16px",
        height: "150px",
        borderRadius: "12px",
        background: "#FFFFFF09",
        backdropFilter: "blur(10px)",
        ":hover": {
          background: "#FFFFFF0F",
          cursor: "pointer",
        },
      }}
      variant="outlined"
      onClick={() => setSelectedProject(proj)}
    >
      <Stack spacing={"16px"} sx={{ width: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
          spacing="12px"
        >
          <Avatar src={proj.image} />
          <Chip
            onClick={() => navigator.clipboard.writeText(proj.id)}
            clickable
            size="small"
            variant="identifier"
            label={proj.id}
          />
        </Stack>
        <Typography>{proj.name}</Typography>

        <Typography fontSize={12} fontWeight={400} color={"GrayText"}>
          Last updated:{" "}
          <span style={{ color: "#fff" }}>
            {moment.unix(proj.updated).format("MMM DD, YYYY hh:mm A")}
          </span>
        </Typography>
      </Stack>
    </Paper>
  );
}
