import React from "react";
import {
  Button,
  Chip,
  Paper,
  PaperProps,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingOverlay from "./LoadingOverlay";
import { Player } from "@lottiefiles/react-lottie-player";

/**
 * A customizable table from PFM design.
 * @returns
 */
export default function PFMTable(props: {
  title?: string; // Title of the table head
  titleBadge?: string; // Badge shown next to the title.
  description?: string; // The description under the title.
  actionButtons?: any; // Action buttons, shown on the right of the header.
  paginated?: boolean; // Enable pagination
  pageNumber?: number; // Current page number. Controls the next/prev buttons
  totalPages?: number; // Total pages. Controls the next/prev buttons.
  nextPage?: (desiredPage: number) => void; // Load desired page.
  prevPage?: (desiredPage: number) => void; // Load desired page.
  tableHead?: any; // TableHead
  tableBody?: any; // TableBody
  busy?: boolean;
  sx?: SxProps;
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        background: "#FFF1",
        borderRadius: "12px",
        pb: "8px",
        display: "flex",
        overflow: "hidden",
        ...props.sx,
      }}
    >
      <Stack sx={{ flex: 1, overflow: "hidden" }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack sx={{ px: "20px", py: "16px" }}>
            <Stack direction={"row"} alignItems={"center"} spacing="8px">
              <Typography fontSize={"24px"} fontWeight={400}>
                {props.title}
              </Typography>
              {props.titleBadge && (
                <Chip
                  variant="outlined"
                  size="small"
                  color="success"
                  label={props.titleBadge}
                />
              )}
            </Stack>

            <Typography fontSize={14} fontWeight={400} color="#94969C">
              {props.description}
            </Typography>
          </Stack>
          {/* Add actions, search, etc.  */}
          <Stack
            sx={{ px: "20px", py: "16px" }}
            direction={"row"}
            spacing="8px"
            justifyContent={"center"}
            alignItems={"center"}
          >
            {props.actionButtons}
          </Stack>
        </Stack>
        <TableContainer sx={{ flex: 1 }}>
          <Table
            stickyHeader
            size="small"
            style={{
              filter: props.busy ? "blur(4px)" : "none",
            }}
          >
            <TableHead>{props.tableHead}</TableHead>

            <TableBody>{props.tableBody}</TableBody>
          </Table>
        </TableContainer>
        {props.paginated && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ px: "24px", py: "16px" }}
          >
            <Typography fontSize={14} fontWeight={500}>
              Page {props.pageNumber! + 1} of {props.totalPages}
            </Typography>
            <Stack direction={"row"} spacing="12px">
              <Button
                onClick={(p) =>
                  props.prevPage?.(Math.max((props?.pageNumber || 0) - 1, 0))
                }
                disabled={props.paginated && (props.pageNumber || 0) <= 0}
                variant="contained"
                color="secondary"
              >
                Previous
              </Button>
              <Button
                onClick={(p) =>
                  props.nextPage?.(
                    Math.min(
                      props?.totalPages || 0,
                      (props.pageNumber || 0) + 1 || 0
                    )
                  )
                }
                disabled={
                  props.paginated &&
                  (props.pageNumber || 0) >= (props.totalPages || 1) - 1
                }
                color="secondary"
                variant="contained"
              >
                Next
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
}
