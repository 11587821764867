export function parseStripeAmount(cents: number) {
  const dollars = Math.floor(cents / 100);
  const remainingCents = cents % 100;
  return { dollars, cents: Math.abs(Math.floor(remainingCents)) };
}
export function getCurrencySymbol(currencyCode: string): string {
  try {
    // Use the Intl.NumberFormat to get the currency symbol for the given currency code.
    const currencyFormatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
    });
    const parts = currencyFormatter.formatToParts(1);

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === "currency") {
        return parts[i].value;
      }
    }
  } catch (error) {
    // Handle any errors, such as unsupported currency codes.
    console.error(
      `Error getting currency symbol for ${currencyCode}: ${error}`
    );
  }

  // Return an empty string if the currency symbol couldn't be determined.
  return "";
}

export function getColorByTag(tag: string) {
  switch (tag.toLowerCase()) {
    case "error":
      return "#F55";
    case "information":
      return "#48F";
    case "warning":
      return "gold";
    case "crash":
      return "#F00";
    default:
      return "#48F";
  }
}
