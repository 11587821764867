import React from "react";
import { Stack } from "@mui/material";

import ZexcoreLogo from "./SidebarLogo";

export default function LoadingOverlay(props: {
  busy: boolean;
  loadingText?: string;
}) {
  return (
    <Stack
      sx={{
        display: props.busy ? "flex" : "none",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        zIndex: 100,
        background: "#101010B0",
      }}
    >
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <ZexcoreLogo
          style={{
            pointerEvents: "none",
          }}
        />
      </Stack>
    </Stack>
  );
}
