import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentProject,
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { IC_REFRESH, IC_SEARCH } from "../../assets";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import {
  ErrorOutline,
  FilterAlt,
  Info,
  InfoRounded,
  Label,
  Warning,
  Workspaces,
} from "@mui/icons-material";
import { rtmGetLogs } from "../../core/rtm";
import { LogMessage, LogMessageKind } from "@zexcore/types";
import moment from "moment";
import { LogStackTrace } from "../../components/LogStreamWidget";

export default function SectionUserLogs() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [project] = useRecoilState(aCurrentProject);
  const { showModal } = useModal();
  const [busy, setBusy] = useState(true);

  const [kindFilter, setKindFilter] = useState<LogMessageKind | "everything">(
    "everything"
  );
  const [showKindFilterMenu, setShowKindFilterMenu] = useState<any>(null);
  const [tags, setTags] = useState<any>([]);
  const [showTagsPopup, setShowTagsPopup] = useState<any>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchText, setSearchText] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [data, setData] = useState<LogMessage[]>([]);

  async function load(page: number) {
    try {
      //Load data
      setBusy(true);
      const _data = await rtmGetLogs({
        project: project!.id!,
        pageNumber: pageNumber,
        kind: kindFilter === "everything" ? undefined : kindFilter,
        tags: tags.length > 0 ? tags : undefined,
      });
      if (_data) {
        setTotalPages(_data.pageCount);
        setTotalLogs(_data.total);
        setPageNumber(page);
        setData(_data.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(` `);
    setSectionDescription(`  `);
    load(0);
  }, []);

  useEffect(() => {
    load(0);
  }, [kindFilter, tags, searchFilter]);

  function RenderLog(log: LogMessage) {
    return (
      <TableRow>
        <TableCell sx={{ width: "auto" }}>
          <Typography color="#94969C" fontSize={12}>
            {moment(new Date(log!.created!)).format("MMM DD, YYYY HH:mm:ss A")}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ width: "auto" }}>
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            {log.kind === LogMessageKind.Warning && (
              <Warning color="warning"></Warning>
            )}
            {log.kind === LogMessageKind.Information && (
              <InfoRounded color="info"></InfoRounded>
            )}
            {log.kind === LogMessageKind.Error && (
              <Warning color="error"></Warning>
            )}
            {log.kind === LogMessageKind.Crash && (
              <ErrorOutline color="error"></ErrorOutline>
            )}
            <Typography sx={{ fontSize: 12, textTransform: "capitalize" }}>
              {log.kind}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{ width: "100%" }} align="left">
          <Typography fontSize={12}>{log.message}</Typography>
          {log.stack && <LogStackTrace stack={log.stack} sx={{ px: 0 }} />}
        </TableCell>

        <TableCell sx={{ width: "auto" }}>
          {log.tags.map((t) => (
            <Chip label={t} size="small" />
          ))}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ py: "12px", px: "32px", overflow: "hidden" }} flex={1}>
      <PFMTable
        sx={{ flex: 1 }}
        title="Logs"
        description="View and analyze your project logs from various sources."
        titleBadge={totalLogs + " logs in total."}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        busy={busy}
        nextPage={(pn) => load(pn)}
        prevPage={(pn) => load(pn)}
        actionButtons={
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing="8px"
            sx={{ width: "100%" }}
          >
            <div
              style={{
                height: 1,
                overflow: "visible",
                position: "relative",
              }}
            >
              <TextField
                onKeyDown={(ke) => {
                  if (ke.key === "Enter") {
                    setSearchFilter(searchText);
                  }
                }}
                value={searchText}
                onChange={(ce) => setSearchText(ce.currentTarget.value)}
                variant="standard"
                size="small"
                placeholder="Search..."
                sx={{
                  border: "1px solid #FFF1",
                  borderRadius: "25px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "12px",
                  width: "110px",
                  right: 0,
                  top: -20,
                  position: "absolute",
                  height: "40px",
                  transition: "all .4s",
                  ":hover": {
                    width: "220px",
                    background: "#FFF3",
                  },
                  ":focus-within": {
                    width: "220px",
                    background: "#FFF3",
                  },
                }}
                inputProps={{
                  style: {
                    marginTop: "6px",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <IC_SEARCH />,
                }}
              />
            </div>
            {/* Popper for Tags  */}
            <ClickAwayListener
              onClickAway={(e) => {
                setShowTagsPopup(null);
              }}
            >
              <Stack>
                <Tooltip title="Filter logs by tags.">
                  <Button
                    onClick={(e) => setShowTagsPopup(e.currentTarget)}
                    variant="contained"
                    color="secondary"
                    sx={{ textTransform: "capitalize" }}
                    startIcon={<Label />}
                  >
                    {tags.length > 0 ? tags.length + " Tags" : "All"}
                  </Button>
                </Tooltip>
                <Popper
                  open={Boolean(showTagsPopup)}
                  anchorEl={showTagsPopup}
                  sx={{ zIndex: 10 }}
                >
                  <Paper
                    sx={{
                      p: "18px",
                      m: "10px",
                      background: "#333",
                      borderRadius: "8px",
                      width: "300px",
                    }}
                    elevation={0}
                  >
                    <Stack spacing="8px">
                      <Typography>Filter logs by tags</Typography>
                      <Autocomplete
                        fullWidth
                        multiple
                        size="small"
                        value={tags}
                        onChange={(e, vs) => setTags(vs)}
                        options={[]}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            {...(params as any)}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              hiddenLabel: true,
                              sx: { borderRadius: "8px" },
                            }}
                            hiddenLabel
                            helperText={"Press enter to separate tags."}
                            placeholder={"Enter tags.."}
                          />
                        )}
                      />
                    </Stack>
                  </Paper>
                </Popper>
              </Stack>
            </ClickAwayListener>

            <Tooltip title="Filter logs by kind.">
              <Button
                onClick={(e) => setShowKindFilterMenu(e.currentTarget)}
                variant="contained"
                color="secondary"
                sx={{ textTransform: "capitalize" }}
                startIcon={<FilterAlt />}
              >
                {kindFilter === "everything" ? "Everything" : kindFilter}
              </Button>
            </Tooltip>
            <Button
              onClick={() => load(pageNumber)}
              disabled={busy}
              variant="contained"
              color="secondary"
              startIcon={<IC_REFRESH />}
            >
              Refresh
            </Button>

            {/* Filter Kind Menu  */}
            <Menu
              open={Boolean(showKindFilterMenu)}
              anchorEl={showKindFilterMenu}
            >
              <MenuItem
                onClick={() => {
                  setKindFilter(LogMessageKind.Information);
                  setShowKindFilterMenu(null);
                }}
              >
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText>Informational</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKindFilter(LogMessageKind.Warning);
                  setShowKindFilterMenu(null);
                }}
              >
                <ListItemIcon>
                  <Warning color="warning" />
                </ListItemIcon>
                <ListItemText>Warnings</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKindFilter(LogMessageKind.Error);
                  setShowKindFilterMenu(null);
                }}
              >
                <ListItemIcon>
                  <Warning color="error" />
                </ListItemIcon>
                <ListItemText>Errors</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKindFilter(LogMessageKind.Crash);
                  setShowKindFilterMenu(null);
                }}
              >
                <ListItemIcon>
                  <ErrorOutline color="error" />
                </ListItemIcon>
                <ListItemText>Crashes</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setKindFilter("everything");
                  setShowKindFilterMenu(null);
                }}
              >
                <ListItemIcon>
                  <Workspaces />
                </ListItemIcon>
                <ListItemText>Everything</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        }
        tableHead={
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Kind</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Tags</TableCell>
          </TableRow>
        }
        tableBody={<>{data?.map((d) => RenderLog(d))}</>}
      />
    </Stack>
  );
}
