import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentProject,
  aCurrentSection,
  aCurrentSectionDescription,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { rtmGetEvents } from "../../core/rtm";

export default function SectionUserEvents() {
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);
  const [project] = useRecoilState(aCurrentProject);
  const [eventCount, setEventCount] = useState(0);
  const [events, setEvents] = useState<
    { event: string; count: number; updated?: number; description?: string }[]
  >([]);

  async function loadData() {
    try {
      //Load data
      setBusy(true);
      const evResponse = await rtmGetEvents(project!.id);
      if (evResponse) {
        setEventCount(evResponse?.total || 0);
        setEvents(evResponse?.events || []);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Events`);
    setSectionDescription(`View events related to the project.`);
    loadData();
  }, []);

  function RenderEvent(tx: {
    event: string;
    count: number;
    updated?: number;
    description?: string;
  }) {
    return (
      <TableRow>
        <TableCell align="left">
          <Chip label={tx.event} variant="filled" />
        </TableCell>
        <TableCell>
          <Typography color="#94969C">{tx.description}</Typography>
        </TableCell>
        <TableCell sx={{ width: "auto", minWidth: "200px" }}>
          {tx.updated && (
            <Typography color="#94969C">
              {moment.unix(tx.updated).format("MMM DD, YYYY HH:mm:ss")} (
              {moment.unix(tx.updated).fromNow()})
            </Typography>
          )}
        </TableCell>
        <TableCell align="left">
          <Chip label={tx.count} variant="identifier" color="info" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "32px", overflow: "hidden" }} flex={1}>
      <Typography fontSize={18} fontWeight={600}>
        Events Overview
      </Typography>
      <Grid
        spacing={"28px"}
        container
        sx={{ py: "28px" }}
        columns={4}
        rowSpacing={"32px"}
      >
        <Grid item>
          <SimpleInsightCard
            accent="#2E90FA"
            title="Total Events"
            value={eventCount.toString()}
          />
        </Grid>
      </Grid>
      <PFMTable
        sx={{ flex: 1 }}
        title="Events"
        description="Click on the event to view its details."
        actionButtons={
          <>
            <Button variant="contained" onClick={loadData} disabled={busy}>
              Refresh
            </Button>
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Latest Event</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        }
        tableBody={events.map((e) => RenderEvent(e))}
      />
    </Stack>
  );
}
