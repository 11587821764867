import React, { useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { IC_ADD_USER } from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import { rtmAddUser } from "../core/rtm";
import { User } from "@zexcore/types";
import { ContentCopy } from "@mui/icons-material";

export default function AddUserDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [role, setRole] = useState("");
  const [createdUser, setCreatedUser] = useState<User & { password: string }>();

  const [busy, setBusy] = useState(false);

  const canCreate = useMemo(() => {
    return Boolean(name) && Boolean(email) && Boolean(role);
  }, [name, email, role]);

  async function addUser() {
    try {
      setBusy(true);
      const u = await rtmAddUser({
        email: email,
        name: name,
        role: role as any,
        comments: comments,
      });
      if (u) {
        enqueueSnackbar(`User ${u.name} was added successfully.`);
        setCreatedUser(u as any);
      }
    } catch (err: any) {
      enqueueSnackbar("Error adding a new user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogTitle>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px" }}
        >
          <IconContainer>
            <IC_ADD_USER />
          </IconContainer>
          <Stack>
            <Typography fontSize={18} fontWeight={600}>
              Add User
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              Fill in the user's details and give them a role.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <PFMInput
                text={name}
                onUpdate={(t) => setName(t)}
                fullWidth
                placeholder="John Doe"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Email Address
              </Typography>
              <PFMInput
                text={email}
                onUpdate={(t) => setEmail(t)}
                fullWidth
                placeholder="someone@example.com"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Role
              </Typography>
              <PFMAutoComplete
                options={[{ label: "Administrator" }, { label: "User" }]}
                placeholder="Select a role.."
                onUpdate={(v) => setRole((v as any)?.label || "")}
                value={{ label: role } as any}
              />
            </Stack>

            <Divider />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <PFMInput
                onUpdate={(t) => setComments(t)}
                multiline
                text={comments}
                rows={4}
                fullWidth
                placeholder="Add comments, reasons, or notes (optional)"
              />
            </Stack>

            {createdUser && (
              <>
                <Divider />
                <Alert>
                  <AlertTitle>User added</AlertTitle>
                  <PFMInput
                    text={createdUser.password}
                    fullWidth
                    label="Password"
                    placeholder="Password"
                    helperText="The password will not be shown again. "
                    endAdornment={
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(createdUser.password);
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    }
                  />
                </Alert>
              </>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={"row"}
          sx={{ width: "100%", px: "32px" }}
          spacing="12px"
        >
          <Button
            disabled={busy}
            onClick={props.closeHandler}
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          {!createdUser && (
            <Button
              disabled={busy || !canCreate}
              onClick={addUser}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Add User
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
