import React, { useState } from "react";
import { useEffect } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Switch,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMInput from "../../components/PFMInput";
import { IC_CHECKBOX, IC_EDIT } from "../../assets";
import { FbAuth, FbStorage } from "../../authentication/firebase";
import { PFMSwitch } from "../../components/PFMSwitch";
import { enqueueSnackbar } from "notistack";
import {
  AuthCredential,
  EmailAuthCredential,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateCurrentUser,
  updatePassword,
  updateProfile,
  validatePassword,
} from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Role } from "@zexcore/types";

export default function SectionSettingsGeneral() {
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [profile, setProfile] = useRecoilState(aProfile);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newsletter, setNewsletter] = useState(false);

  const [editingName, setEditingName] = useState(false);
  const [savingName, setSavingName] = useState(false);
  const [nameSaved, setNameSaved] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [busy, setBusy] = useState(false);

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          // Here we update profile
          await updateProfile(FbAuth.currentUser!, {
            photoURL: url,
          });
          enqueueSnackbar("Profile photo updated successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function loadProfile() {
    try {
      // const prof = await RestGetUserProfile();
      // setProfile(prof);
      // setName(prof?.name || "");
      // setEmail(prof?.email || "");
      // setImage(FbAuth.currentUser?.photoURL || "");
      // setNewsletter(prof?.email_preferences?.newsletter || false);
      // setPassword("***************");
    } catch (err: any) {
      // Error loading profile.
      enqueueSnackbar("Error loading profile. Please try again.", {
        variant: "error",
      });
      console.log("Error loading profile.");
      console.log(err);
    }
  }

  async function updatePrefs() {
    try {
      setBusy(true);
      // Update profile
      // await RestUpdatePrefs({
      //   newsletter: newsletter ? false : true,
      // });
      // setNewsletter(newsletter ? false : true);
    } catch (err: any) {
      enqueueSnackbar("Error saving profile data.", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  async function saveName() {
    try {
      setBusy(true);
      setSavingName(true);
      // Update profile
      await updateProfile(FbAuth.currentUser!, {
        displayName: name,
      });

      setEditingName(!editingName);
      setNameSaved(true);
    } catch (err: any) {
      enqueueSnackbar("Error saving profile data.", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
    setSavingName(false);
  }

  async function changePassword() {
    try {
      setBusy(true);
      const oldCreds = await EmailAuthProvider.credential(email!, password);
      await reauthenticateWithCredential(FbAuth.currentUser!, oldCreds);
      await updatePassword(FbAuth.currentUser!, newPassword);
      setPassword("***************");
      setPasswordChanged(true);
      setEditingPassword(false);
      enqueueSnackbar("Password updated. ", { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar("Error updating password. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  async function removePhoto() {
    try {
      setBusy(true);
      // Update profile
      await updateProfile(FbAuth.currentUser!, {
        photoURL: "",
      });
      setImage("");
      setUploadInfo("");
      enqueueSnackbar("Profile photo has been removed.", { variant: "info" });
    } catch (err: any) {
      enqueueSnackbar("Error saving profile data.", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`General Settings`);
    setSectionDescription(`Basic account settings and personalization.`);
    loadProfile();
  }, []);

  return (
    <Stack sx={{ p: "40px" }} spacing={"44px"}>
      {/* Sub Container with 48px spacing.  */}
      <Stack spacing={"40px"}>
        <Typography fontSize={24} fontWeight={500}>
          Personal Details
        </Typography>

        <Stack direction={"row"} spacing={"24px"}>
          <Stack sx={{ position: "relative" }}>
            <Avatar
              sx={{ height: "96px", width: "96px" }}
              src={image || uploadInfo}
            />
            {uploadState === "uploading" && (
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size="96px"
                sx={{ position: "absolute" }}
              />
            )}
          </Stack>
          <Stack
            flex={1}
            sx={{ height: "100%" }}
            justifyContent={"space-around"}
          >
            <Typography fontSize={18} fontWeight={400}>
              Profile Photo
            </Typography>
            <Stack direction={"row"} spacing={"8px"}>
              <input
                id="file-input"
                style={{ position: "absolute", opacity: 0, zIndex: -999999 }}
                type="file"
                onChange={(f) => {
                  if (f.target.files && f.target.files?.length > 0) {
                    uploadAttachment(f.target.files[0]);
                  }
                }}
              />
              <Button
                onClick={() => document.getElementById("file-input")?.click()}
                disabled={busy}
                color="secondary"
                variant="contained"
              >
                {image || uploadInfo ? "Change Photo" : "Upload Photo"}
              </Button>
              {(image || uploadInfo) && (
                <Button disabled={busy} onClick={removePhoto} color="error">
                  Remove Photo
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing="8px">
          <PFMInput
            disabled={busy}
            label="Full Name"
            text={name}
            onUpdate={(t) => {
              if (editingName) setName(t);
            }}
            sx={{ width: "380px" }}
            endAdornment={
              <>
                <Stack
                  direction={"row"}
                  sx={{ height: "100%", width: "48px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Divider orientation="vertical" />
                  {savingName && (
                    <CircularProgress
                      color="primary"
                      sx={{ height: "100%", width: "100%", p: "10px" }}
                    />
                  )}
                  {!savingName && (
                    <IconButton
                      color="input"
                      onClick={() => {
                        if (editingName) {
                          // save
                          saveName();
                        } else {
                          setEditingName(!editingName);
                        }
                      }}
                    >
                      {editingName === true && <IC_CHECKBOX />}
                      {editingName === false && <IC_EDIT />}
                    </IconButton>
                  )}
                </Stack>
              </>
            }
          />
          {nameSaved && (
            <Typography color="#47CD89">Name Changed Successfully!</Typography>
          )}
        </Stack>
        <PFMInput label="Email Address" text={email} sx={{ width: "380px" }} />
        <Stack spacing="8px">
          <PFMInput
            disabled={busy}
            label={editingPassword ? "Old Password" : "Password"}
            text={password}
            password
            onUpdate={(t) => {
              if (editingPassword) setPassword(t);
            }}
            placeholder="Enter old password..."
            sx={{ width: "380px" }}
            endAdornment={
              !editingPassword ? (
                <>
                  <Stack
                    direction={"row"}
                    sx={{ height: "100%", width: "250px" }}
                    alignItems={"center"}
                  >
                    <Divider orientation="vertical" />
                    <Button
                      disabled={busy}
                      sx={{
                        color: "#94969C",
                        height: "100%",
                      }}
                      onClick={() => {
                        setPassword("");
                        setPasswordChanged(false);
                        setEditingPassword(true);
                      }}
                    >
                      Change Password
                    </Button>
                  </Stack>
                </>
              ) : undefined
            }
          />
        </Stack>
        {editingPassword && (
          <Stack spacing="8px">
            <PFMInput
              disabled={busy}
              label={"New Password"}
              text={newPassword}
              password
              onUpdate={(t) => {
                setNewPassword(t);
              }}
              placeholder="Enter your new password..."
              sx={{ width: "380px" }}
              endAdornment={
                <>
                  <Stack
                    direction={"row"}
                    sx={{ height: "100%", width: "250px" }}
                    alignItems={"center"}
                  >
                    <Divider orientation="vertical" />
                    <Button
                      disabled={busy}
                      sx={{
                        color: "#94969C",
                        height: "100%",
                      }}
                      onClick={changePassword}
                    >
                      Update Password
                    </Button>
                  </Stack>
                </>
              }
            />

            {passwordChanged && (
              <Typography color="#47CD89">
                Password Changed Successfully!
              </Typography>
            )}
          </Stack>
        )}
      </Stack>

      {/* Email prefs are only shown for users with Customer role  */}
      {profile?.role === Role.User && (
        <>
          <Divider sx={{ my: "4px" }} />
          <Stack spacing="40px">
            <Typography fontSize={24} fontWeight={500}>
              Email Preferences
            </Typography>
            {/* <Stack direction={"row"} alignItems={"center"} spacing="24px">
              <PFMSwitch disabled={busy} />
              <Stack>
                <Typography fontSize={16} fontWeight={500}>
                  Subscription Emails
                </Typography>
                <Typography fontSize={16} fontWeight={400} color="#98A2B3">
                  Send email notifications regarding my subscriptions.
                </Typography>
              </Stack>
            </Stack> */}
            {/* <Stack direction={"row"} alignItems={"center"} spacing="24px">
              <PFMSwitch disabled={busy} />
              <Stack>
                <Typography fontSize={16} fontWeight={500}>
                  Account Activity
                </Typography>
                <Typography fontSize={16} fontWeight={400} color="#98A2B3">
                  Send email notifications for login, password change, etc.
                </Typography>
              </Stack>
            </Stack> */}
            <Stack direction={"row"} alignItems={"center"} spacing="24px">
              <PFMSwitch
                disabled={busy}
                onChange={(c) => updatePrefs()}
                checked={newsletter}
              />
              <Stack>
                <Typography fontSize={16} fontWeight={500}>
                  Newsletter
                </Typography>
                <Typography fontSize={16} fontWeight={400} color="#98A2B3">
                  Receive promotional emails and other product offers from PFM.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}
