import React, { useEffect, useState } from "react";
import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import PFMTable from "../../components/PFMTable";
import { IC_REFRESH } from "../../assets";
import { useModal } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { Add } from "@mui/icons-material";
import CreateApiKeyDialog from "../../dialogs/CreateApiKey";
import { rtmGetApiKeys } from "../../core/rtm";
import { APIKey } from "@zexcore/types";
import moment from "moment";
import DeleteApiKeyDialog from "../../dialogs/DeleteApiKey";

export default function SectionUserApiKeys() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const { showModal } = useModal();
  const [busy, setBusy] = useState(true);

  const [data, setData] = useState<any[]>([]);

  async function load() {
    try {
      //Load data
      setBusy(true);
      const keys = await rtmGetApiKeys();
      setData(keys || []);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }
  function showDeleteDialog(key: APIKey) {
    const modal = showModal(DeleteApiKeyDialog, {
      closeHandler(result) {
        modal.destroy();
        if (result) load();
      },
      data: key,
    });
  }

  function showCreateKeyDialog() {
    const modal = showModal(CreateApiKeyDialog, {
      closeHandler(result) {
        modal.destroy();
        load();
      },
    });
  }

  useEffect(() => {
    setSection(` `);
    setSectionDescription(` `);
    load();
  }, []);

  function RenderKey(key: APIKey) {
    return (
      <TableRow>
        <TableCell align="left">
          <Typography fontSize={14} fontWeight={500}>
            {key.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(key.created).format("MMM DD, YYYY HH:mm:ss A")} -{" "}
            {moment.unix(key.created).fromNow()}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            0x{key.secret.substring(0, 16)}...
            {key.secret.substring(
              key.secret.length - 10,
              key.secret.length - 1
            )}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Button onClick={() => showDeleteDialog(key)}>Delete</Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ py: "12px", px: "32px" }} flex={1}>
      <PFMTable
        sx={{ height: "100%" }}
        title="API Keys"
        description="View and manage your API keys."
        titleBadge={data.length + " Keys"}
        actionButtons={
          <>
            <Button
              onClick={load}
              disabled={busy}
              variant="contained"
              color="secondary"
              startIcon={<IC_REFRESH />}
            >
              Refresh
            </Button>
            <Button
              onClick={showCreateKeyDialog}
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={<Add />}
            >
              Create
            </Button>
          </>
        }
        tableHead={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Hash</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        }
        tableBody={<>{data?.map((d) => RenderKey(d))}</>}
      />
    </Stack>
  );
}
