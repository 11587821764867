const debug = {
  version: "1.0.0",
  build: "Dec 1, 2023",
  api: "http://localhost:80",
  rtm: "ws://localhost:80",
  firebaseConfig: {
    apiKey: "AIzaSyBsMwtDTf0MgJ63MMVZ47a81FMTESD6Dw4",
    authDomain: "zexcore-beta.firebaseapp.com",
    projectId: "zexcore-beta",
    storageBucket: "zexcore-beta.appspot.com",
    messagingSenderId: "152949481968",
    appId: "1:152949481968:web:95d65075483b8dc9c3bd35",
  },
  zexcore: {
    apiKey: "e40ad3abf928d288f60249913389a589120c7f410fd4ec4163",
    projectId: "14033854350453325824",
  },
};

const prod = {
  version: "2024.04.24",
  build: "#10",
  api: "https://core-api.zexware.com",
  rtm: "wss://core-api.zexware.com",
  firebaseConfig: {
    apiKey: "AIzaSyBsMwtDTf0MgJ63MMVZ47a81FMTESD6Dw4",
    authDomain: "zexcore-beta.firebaseapp.com",
    projectId: "zexcore-beta",
    storageBucket: "zexcore-beta.appspot.com",
    messagingSenderId: "152949481968",
    appId: "1:152949481968:web:95d65075483b8dc9c3bd35",
  },
  zexcore: {
    apiKey: "e40ad3abf928d288f60249913389a589120c7f410fd4ec4163",
    projectId: "14033854350453325824",
  },
};

const DEBUG = process.env.REACT_APP_DEBUG === "true";

export const AppConfig = DEBUG ? debug : prod;
