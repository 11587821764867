import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentProject,
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import SimpleInsightCard from "../../components/SimpleInsightCard";
import { useNavigate } from "react-router";
import ProjectCard from "../../components/ProjectCard";
import { rtmGetProjects } from "../../core/rtm";
import { Project } from "@zexcore/types";
import CreateProjectCard from "../../components/CreateProjectCard";
import { useModal } from "mui-modal-provider";
import CreateProjectDialog from "../../dialogs/CreateProject";
import {
  Add,
  AdsClick,
  AvTimer,
  Event,
  PlayArrow,
  QueryStats,
  Stop,
  Terminal,
} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import CreateLogsWidgetDialog from "../../dialogs/CreateLogStreamWidget";
import LogStreamWidget from "../../components/LogStreamWidget";
import CreateEventsWidgetDialog from "../../dialogs/CreateEventStreamWidget";
import EventStreamWidget from "../../components/EventStreamWidget";

export default function SectionUserDashboard() {
  const [profile] = useRecoilState(aProfile);
  const [busy, setBusy] = useState(false);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [selectedProject, setSelectedProject] = useRecoilState(aCurrentProject);
  const [projects, setProjects] = useState<Project[]>([]);
  const [showAddWidgetMenu, setShowAddWidgetMenu] = useState<any>(null);

  const theme = useTheme();
  const nav = useNavigate();
  const { showModal } = useModal();

  async function load() {
    try {
      const prjs = await rtmGetProjects();
      if (prjs) {
        setProjects(prjs);
      }
    } catch (err: any) {}
  }

  function showCreateProjectDialog() {
    const modal = showModal(CreateProjectDialog, {
      closeHandler(result) {
        if (result) load();
        modal.destroy();
      },
    });
  }

  function showCreateLogsWidgetDialog() {
    const modal = showModal(CreateLogsWidgetDialog, {
      closeHandler(result) {
        if (result) load();
        modal.destroy();
      },
    });
  }

  function showCreateEventsWidgetDialog() {
    const modal = showModal(CreateEventsWidgetDialog, {
      closeHandler(result) {
        if (result) load();
        modal.destroy();
      },
    });
  }

  useEffect(() => {
    setSection(`Dashboard`);
    setSectionDescription(`Welcome back, ${profile?.name}!`);
    load();
  }, []);

  // If no project is selected, we show projects cards.
  if (!selectedProject) {
    return (
      <Stack sx={{ p: "32px" }} spacing={"12px"}>
        <Typography fontSize={18} fontWeight={500}>
          Your Projects
        </Typography>
        <Stack
          flexWrap={"wrap"}
          direction={"row"}
          columnGap={"12px"}
          rowGap={"12px"}
        >
          {projects.map((p) => (
            <ProjectCard project={p} />
          ))}
          {/* Create Project Card  */}
          <CreateProjectCard onClick={showCreateProjectDialog} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ pb: "12px", px: "32px", overflow: "hidden" }} flex={1}>
      {/* The top panel of the widgets  */}
      <Stack direction={"row"} alignItems={"center"} spacing="12px">
        <Button
          id="addWidgetMenuParent"
          onClick={(e) => setShowAddWidgetMenu(e.currentTarget)}
          variant="contained"
          startIcon={<Add />}
        >
          Add Widget
        </Button>

        <Menu
          open={Boolean(showAddWidgetMenu)}
          anchorEl={showAddWidgetMenu}
          onClose={() => setShowAddWidgetMenu(null)}
        >
          <MenuItem
            onClick={() => {
              showCreateEventsWidgetDialog();
              setShowAddWidgetMenu(null);
            }}
          >
            <ListItemIcon>
              <AdsClick />
            </ListItemIcon>
            <ListItemText>Events</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              showCreateLogsWidgetDialog();
              setShowAddWidgetMenu(null);
            }}
          >
            <ListItemIcon>
              <Terminal />
            </ListItemIcon>
            <ListItemText>Log Stream</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setShowAddWidgetMenu(null)}>
            <ListItemIcon>
              <AvTimer />
            </ListItemIcon>
            <ListItemText>Counter</ListItemText>
          </MenuItem>
        </Menu>
      </Stack>
      {(!selectedProject.dashboard ||
        selectedProject.dashboard.length === 0) && (
        <Stack
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"8px"}
        >
          <Typography sx={{ opacity: 0.5 }}>Nothing to show.</Typography>
          <Button
            id="addWidgetMenuParent"
            onClick={(e) => setShowAddWidgetMenu(e.currentTarget)}
            startIcon={<Add />}
          >
            Add Widget
          </Button>
        </Stack>
      )}
      {/* The widgets container  */}
      {selectedProject.dashboard && selectedProject.dashboard.length > 0 && (
        <Stack
          flex={1}
          flexWrap={"wrap"}
          direction={"row"}
          rowGap={"8px"}
          columnGap={"8px"}
          sx={{ my: "8px", overflow: "auto" }}
        >
          {selectedProject.dashboard.map((l, i) => (
            <>
              {l.type === "logs" && l.source.type === "log_messages" && (
                <LogStreamWidget
                  layout={l as any}
                  projectId={selectedProject.id}
                  widgetIndex={i}
                />
              )}
              {l.type === "events" && (
                <EventStreamWidget
                  layout={l as any}
                  projectId={selectedProject.id}
                  widgetIndex={i}
                />
              )}
            </>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
