import { Project, User } from "@zexcore/types";
import { atom } from "recoil";

const localStorageEffect =
  (key: any) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const aCurrentSection = atom({
  key: "pfm_current_section",
  default: "Dashboard",
});
export const aCurrentSectionDescription = atom({
  key: "pfm_current_section_desc",
  default: "Welcome",
});

export const aProfile = atom<User | null>({
  key: "pfm_profile",
  default: null,
});

export const aHideSidebar = atom<boolean>({
  default: false,
  key: "pfm_hide_sidebar",
});

export const aCurrentProject = atom<Project | null>({
  key: "zc_project",
  default: null,
  effects: [localStorageEffect("zc_project")],
});
