import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import {
  IC_ADD_USER,
  IC_ALERT_WARNING,
  IC_USER_BLOCK,
  IC_USER_MINUS,
} from "../assets";
import IconContainer from "../components/IconContainer";
import PFMAutoComplete from "../components/PFMAutoComplete";
import { enqueueSnackbar } from "notistack";
import PFMCheckbox from "../components/PFMCheckbox";
import { User } from "@zexcore/types";

export default function SuspendUserDialog(props: DialogProps<User>) {
  const [reason, setReason] = useState("");
  const [notify, setNotify] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false);

  const [busy, setBusy] = useState(false);

  async function suspendUser() {
    try {
      setBusy(true);
      // await RestSuspendUser(props.data?.uid!, reason, notify);
      enqueueSnackbar(
        `User account ${props.data?.name} has been suspended permanently.`,
        {
          variant: "success",
        }
      );
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error updating user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <IC_USER_BLOCK />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Suspend User
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Ban or permanently suspend a user account.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Email
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Current Role
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.role}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.comments || "N/A"}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Reasons
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <PFMInput
                  onUpdate={(t) => setReason(t)}
                  multiline
                  disabled={busy}
                  text={reason}
                  rows={4}
                  fullWidth
                  placeholder="Explain why this user is being suspended. You can choose to inform the user as well by checking the box below."
                />

                <PFMCheckbox
                  label="Notify user via email"
                  value={notify}
                  onUpdate={(n) => setNotify(n)}
                />
              </Stack>
            </Stack>

            <Alert
              color="warning"
              variant="filled"
              icon={<IC_ALERT_WARNING fill="transparent" stroke="#F79009" />}
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                The user will no longer be able to sign in or use their account,
                or even sign up again. This will also cancel their active
                subscriptions on Stripe immediately. Proceed with caution.
              </Typography>
            </Alert>
            <PFMCheckbox
              value={accept}
              onUpdate={(c) => setAccept(c)}
              label="I understand the consequences of this action and I wish to proceed."
            />
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              No, Cancel
            </Button>
            <Button
              onClick={suspendUser}
              disabled={busy || !accept}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Suspend User
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
