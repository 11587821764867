import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import { IC_EMAIL, IC_PASSWORD } from "../../assets";
import { useLocation } from "react-router";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { FbAuth } from "../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import PFMCheckbox from "../../components/PFMCheckbox";
import LoadingOverlay from "../../components/LoadingOverlay";
import ZexcoreLogo from "../../components/SidebarLogo";

export default function PageUpdatePassword() {
  const [verified, setVerified] = useState(false);
  const [busy, setBusy] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);

  const [isChanged, setIsChanged] = useState(false);
  const loc = useLocation();
  const code = loc.state?.code;

  async function updatePassword() {
    try {
      if (password !== passwordConfirm) {
        setError("password");
        return;
      }
      const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
      if (!symPat.test(password)) {
        setError("password");
        return;
      }
      if (password.length < 8) {
        setError("password");
        return;
      }
      setBusy(true);
      await confirmPasswordReset(FbAuth, code, password);
      // Send verification email.
      enqueueSnackbar("Your password has been updated.", {
        variant: "success",
      });
      // IF continue url is set, we navigatte.
      setIsChanged(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error updating your password. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
      setError("error");
    }
    setBusy(false);
  }

  async function verifyCode() {
    try {
      setBusy(true);
      // Proceed.
      const email = await verifyPasswordResetCode(FbAuth, code);
      setEmail(email);
      setVerified(true);
    } catch (err: any) {
      enqueueSnackbar(
        "Error verifying password reset code. Please contact administrators.",
        {
          variant: "error",
        }
      );
      console.log(err);
      setError("error");
      setVerified(false);
    }
    setBusy(false);
  }

  useEffect(() => {
    verifyCode();
  }, []);

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  return (
    <Stack sx={{ height: "100vh" }}>
      <LoadingOverlay busy={busy} />
      <ZexcoreLogo
        style={{
          position: "fixed",
          top: "64px",
          left: "80px",
          pointerEvents: "none",
        }}
      />
      {/* Shown when the link has been sent */}
      {isChanged && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <SvgIcon sx={{ fontSize: "96px" }}>
              <IC_EMAIL />
            </SvgIcon>
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Password updated!
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              Your password has been changed successfully. Please login.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <Stack
                justifyContent={"start"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
      {/* Shown when the link has not yet been sent  */}
      {!isChanged && verified && (
        <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
          <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Update Password
            </Typography>
            <Typography
              textAlign={"center"}
              width={"360px"}
              sx={{ fontSize: "16px", color: "#94969C" }}
            >
              Please enter the new password for your account.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <PFMInput
                text={password}
                onUpdate={(t) => setPassword(t)}
                password
                label="Password"
                errorText={
                  error === "password" ? "Please enter a valid password." : ""
                }
                important
                placeholder="Enter your new password "
                startIcon={IC_PASSWORD}
              />

              <PFMInput
                text={passwordConfirm}
                onUpdate={(t) => setPasswordConfirm(t)}
                password
                errorText={
                  error === "password" ? "Please enter a valid password." : ""
                }
                label="Confirm Password"
                important
                placeholder="Enter your new password again "
                startIcon={IC_PASSWORD}
              />

              <Stack spacing={"2px"}>
                <PFMCheckbox
                  value={passwordHasEnoughCharacters}
                  label="Must be at least 8 characters"
                />
                <PFMCheckbox
                  value={passwordHasSymbol}
                  label="Must contain one special character"
                />
              </Stack>

              <Button
                onClick={updatePassword}
                sx={{ mt: "4px" }}
                variant="contained"
              >
                Update password
              </Button>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Link href="/login" underline="none" fontSize={"14px"}>
                  &lt;- Back to log in
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      )}
      {!verified ||
        (error === "error" && (
          <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
            <Stack alignItems={"center"} sx={{ mt: "32px" }} spacing="12px">
              <Typography
                fontSize={"30px"}
                fontWeight={"600"}
                sx={{ color: "#F5F5F6" }}
              >
                ERROR
              </Typography>
              <Typography
                textAlign={"center"}
                width={"360px"}
                sx={{ fontSize: "16px", color: "#94969C" }}
              >
                Code verification failed. Either the link is expired or invalid.
                Please try again.
              </Typography>
            </Stack>
            <FormGroup>
              <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
                <Stack
                  justifyContent={"start"}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ mt: "12px" }}
                  spacing="8px"
                >
                  <Link href="/login" underline="none" fontSize={"14px"}>
                    &lt;- Back to log in
                  </Link>
                </Stack>
              </Stack>
            </FormGroup>
          </Stack>
        ))}
    </Stack>
  );
}
