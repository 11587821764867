import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { APIKey } from "@zexcore/types";
import IconContainer from "../components/IconContainer";
import { Delete, Warning } from "@mui/icons-material";
import PFMInput from "../components/PFMInput";
import { rtmDeleteApiKey } from "../core/rtm";
import moment from "moment";

export default function DeleteApiKeyDialog(props: DialogProps<APIKey>) {
  const [busy, setBusy] = useState(false);
  const key = props.data;

  async function deleteKey() {
    // Update project name and logo
    try {
      if (!key) {
        return;
      }
      setBusy(true);
      // Create the key
      const deleted = await rtmDeleteApiKey(key.secret);
      if (deleted) {
        enqueueSnackbar("API Key deleted successfully.", {
          variant: "success",
        });
        props.closeHandler(true);
      }
    } catch (err: any) {
      enqueueSnackbar("Error deleting API key. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <Delete />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Delete API Key
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Confirm API Key deletion
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Stack sx={{ px: "20px" }} spacing={"16px"}>
            {/* Basic project settings */}

            <PFMInput disabled label="Name" important text={key?.name} />
            <PFMInput
              text={moment.unix(key!.created).format("MMM DD, YYYY HH:mm:ss A")}
              label="Created"
              disabled
            />

            <Alert
              variant="filled"
              icon={<Warning color="warning" />}
              color="warning"
            >
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                Deleting this key will immediately revoke access to any apps or
                services using this API key. Do you really want to proceed?
              </Typography>
            </Alert>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              disabled={busy}
              onClick={deleteKey}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
