import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import { enqueueSnackbar } from "notistack";
import { Project } from "@zexcore/types";
import IconContainer from "../components/IconContainer";
import { Add, ContentCopy, Warning } from "@mui/icons-material";
import PFMInput from "../components/PFMInput";
import { rtmCreateApiKey } from "../core/rtm";

export default function CreateApiKeyDialog(props: DialogProps<Project>) {
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState<string>();
  const [created, setCreated] = useState(false);
  const [secret, setSecret] = useState("");

  async function save() {
    // Update project name and logo
    try {
      if (!name) {
        enqueueSnackbar("Please enter a project name.", { variant: "error" });
        return;
      }
      setBusy(true);
      // Create the key
      const newKey = await rtmCreateApiKey(name);
      if (newKey) {
        // Created
        setSecret(newKey.secret);
        setCreated(true);
      }
    } catch (err: any) {
      enqueueSnackbar("Error creating API key. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <Add />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Create API Key
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Create an API key for use in Zexcore SDK
              </Typography>
            </Stack>
          </Stack>
          <Divider />

          <Stack sx={{ px: "20px" }} spacing={"16px"}>
            {/* Basic project settings */}

            <PFMInput
              disabled={created}
              label="Name"
              important
              text={name}
              placeholder="Enter a name for the API key..."
              onUpdate={(t) => setName(t)}
            />
            {created && (
              <PFMInput
                text={secret}
                label="API Key"
                buttonIcon={ContentCopy}
                buttonClick={() => {
                  navigator.clipboard.writeText(secret);
                  enqueueSnackbar("API Key copied to clipboard.", {
                    variant: "warning",
                  });
                }}
              />
            )}

            {created && (
              <Alert
                variant="filled"
                icon={<Warning color="warning" />}
                color="warning"
              >
                <AlertTitle>Note</AlertTitle>
                <Typography>
                  For security reasons, this API key above will not be shown
                  again.
                </Typography>
              </Alert>
            )}
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            {!created && (
              <Button
                disabled={busy}
                onClick={save}
                size="large"
                fullWidth
                variant="contained"
                color="primary"
              >
                Create
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
