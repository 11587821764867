import React from "react";
import { useEffect } from "react";
import { Stack } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";

export default function SectionSettingsSecurity() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);

  useEffect(() => {
    setSection(`Security`);
    setSectionDescription(
      `Account protection, two factor authentication, and more.`
    );
  }, []);

  return <Stack sx={{ p: "40px" }}></Stack>;
}
