import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  CircularProgress,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentProject,
  aCurrentSection,
  aCurrentSectionDescription,
  aHideSidebar,
  aProfile,
} from "../core/states";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import LoadingOverlay from "../components/LoadingOverlay";
import Sidebar from "../components/Sidebar";
import SectionAdminDashboard from "../sections/admin/dashboard";
import SectionAdminUsers from "../sections/admin/users";
import SectionUserDashboard from "../sections/user/dashboard";
import { useModal } from "mui-modal-provider";
import { Project, Role } from "@zexcore/types";
import { rtmGetProfile, rtmGetProjects } from "../core/rtm";
import { IC_BACK, IC_SETTINGS } from "../assets";
import ProjectSettingsDialog from "../dialogs/ProjectSettings";
import SectionUserApiKeys from "../sections/user/keys";
import SectionUserLogs from "../sections/user/logs";
import SectionUserEvents from "../sections/user/events";

export default function PageDashboard() {
  const [section] = useRecoilState(aCurrentSection);
  const [hideSidebar] = useRecoilState(aHideSidebar);
  const [sectionInfo] = useRecoilState(aCurrentSectionDescription);
  const [profile, setProfile] = useRecoilState(aProfile);
  const nav = useNavigate();
  const { showModal } = useModal();
  const routeInfo = useLocation();
  const [connectionError, setConnectionError] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useRecoilState(aCurrentProject);

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      const prjs = await rtmGetProjects();
      if (prjs) {
        setProjects(prjs);
      }
    } catch (err: any) {
      console.log("Error loading profile.");
      console.log(err);
    }
  }

  function showProjectSettingsDialog(p: Project) {
    const modal = showModal(ProjectSettingsDialog, {
      closeHandler(result) {
        modal.destroy();
      },
      data: p,
    });
  }

  useEffect(() => {
    loadProfile();
  }, []);

  if (!profile) {
    return <LoadingOverlay busy />;
  }

  return (
    <Stack sx={{ height: "100vh" }} direction={"row"}>
      {connectionError && (
        <Stack
          sx={{
            position: "absolute",
            background: "gold",
            color: "black",
            width: "100%",
          }}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing="8px"
        >
          <Typography>Connection interrupted. Reconnecting...</Typography>
          <CircularProgress size={"20px"} />
        </Stack>
      )}
      {!hideSidebar &&
        (profile.role === Role.Administrator || selectedProject) && <Sidebar />}
      <Stack flex={1} overflow={"auto"}>
        {section && (
          <Stack sx={{ my: "32px" }}>
            {selectedProject && (
              <Stack direction={"row"} spacing="8px" sx={{ width: "100%" }}>
                <IconButton onClick={() => setSelectedProject(null)}>
                  <IC_BACK />
                </IconButton>
                <Select
                  key={projects as any}
                  color="primary"
                  value={selectedProject.id}
                  size="small"
                  sx={{ color: "paleturquoise" }}
                >
                  {projects.map((p) => (
                    <MenuItem value={p.id}>{p.name}</MenuItem>
                  ))}
                  <Divider />
                  <MenuItem value={"0"}>Create a project...</MenuItem>
                </Select>
                <Tooltip title="Project Settings">
                  <IconButton
                    onClick={() => showProjectSettingsDialog(selectedProject)}
                  >
                    <IC_SETTINGS fill="#FFFC" />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
            <Stack sx={{ mx: "32px" }}>
              <Typography fontSize={36} fontWeight={600}>
                {section || "Dashboard"}
              </Typography>
              <Typography fontSize={16} fontWeight={400}>
                {sectionInfo || "Welcome"}
              </Typography>
            </Stack>
          </Stack>
        )}
        {profile && profile.role === Role.Administrator && (
          <Routes>
            <Route path="/admin/" element={<SectionAdminDashboard />} />
            <Route
              path="/admin/dashboard"
              element={<SectionAdminDashboard />}
            />

            <Route path="/admin/users" element={<SectionAdminUsers />} />

            <Route path="/*" element={<SectionAdminDashboard />} />
          </Routes>
        )}
        {profile && profile.role === Role.User && (
          <Routes>
            <Route path="/user/" element={<SectionUserDashboard />} />
            {selectedProject && (
              <Route
                path="/user/dashboard"
                element={<SectionUserDashboard />}
              />
            )}
            <Route path="/user/keys" element={<SectionUserApiKeys />} />
            {selectedProject && (
              <>
                <Route path="/user/logs" element={<SectionUserLogs />} />
                <Route path="/user/events" element={<SectionUserEvents />} />
              </>
            )}
            {/* <Route path="/user/support" element={<SectionUserSupport />} /> */}
            {/* <Route path="/user/ticket/:id" element={<SectionTicket />} /> */}
            <Route path="/*" element={<SectionUserDashboard />} />
          </Routes>
        )}
      </Stack>
    </Stack>
  );
}
