import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import {
  IC_ADD,
  IC_ADD_IMAGE,
  IC_PEN,
  IC_CLOSE,
  IC_HELP,
  IC_CART_PLUS,
} from "../assets";
import IconContainer from "../components/IconContainer";
import { enqueueSnackbar } from "notistack";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FbAuth, FbStorage } from "../authentication/firebase";
import PFMAutoComplete, {
  AutocompleteOption,
} from "../components/PFMAutoComplete";
import { Add } from "@mui/icons-material";
import { rtmCreateProject } from "../core/rtm";
import { useRecoilState } from "recoil";
import { aCurrentProject } from "../core/states";

export default function CreateProjectDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [groups, setGroups] = useState<AutocompleteOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<AutocompleteOption>();
  const [selectedProject, setSelectedProject] = useRecoilState(aCurrentProject);

  // File upload
  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  const [busy, setBusy] = useState(false);

  async function uploadAttachment(attachment: File) {
    try {
      setBusy(true);
      const r = ref(
        FbStorage,
        "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
      );
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {
        customMetadata: {
          uid: FbAuth.currentUser!.uid,
        },
      });
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          enqueueSnackbar("File uploaded successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  async function createProject() {
    try {
      // Validate
      if (!name) {
        enqueueSnackbar("Please enter a name for your project.", {
          variant: "error",
        });
        return;
      }
      if (!selectedGroup) {
        enqueueSnackbar("Please select the target platform of the project.", {
          variant: "error",
        });
        return;
      }
      setBusy(true);
      const proj = await rtmCreateProject({
        image: uploadInfo,
        name: name,
        platform: selectedGroup.id,
      });
      if (proj) {
        enqueueSnackbar("Project created successfully.", {
          variant: "success",
        });
        props.closeHandler(true);
        // Set selected project to this new project.
        setSelectedProject(proj);
      }
    } catch (err: any) {
      enqueueSnackbar("Error creating a new project. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function loadMlGroups() {
    const oos: AutocompleteOption[] = [
      {
        label: "React",
        id: "react",
      },
      {
        label: "ReactNative",
        id: "react-native",
      },
      {
        label: "NodeJS",
        id: "nodejs",
      },
      {
        label: "Generic",
        id: "generic",
      },
    ];
    setGroups(oos);
  }

  useEffect(() => {
    loadMlGroups();
  }, []);

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
      maxWidth={"md"}
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <IconContainer>
              <Add />
            </IconContainer>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Create Project
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Enter the details of your new project.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                className="required"
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Name
              </Typography>
              <PFMInput
                text={name}
                disabled={busy}
                onUpdate={(t) => setName(t)}
                fullWidth
                placeholder="My Amazing App"
              />
            </Stack>

            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                sx={{
                  maxWidth: "220px",
                  minWidth: "150px",
                }}
                spacing={"8px"}
              >
                <Typography className="required" fontSize={14} fontWeight={500}>
                  Platform
                </Typography>
                <Tooltip title="This will help us create the dashboard layouts for you.">
                  <IC_HELP />
                </Tooltip>
              </Stack>
              <PFMAutoComplete
                important
                key={groups.length}
                options={groups}
                placeholder="Select project platform..."
                onUpdate={(sel) => setSelectedGroup(sel as any)}
                value={selectedGroup}
              />
            </Stack>
            <input
              id="file-upload"
              type="file"
              style={{ opacity: 0, position: "absolute", zIndex: "-9999" }}
              onChange={(fe) => {
                uploadAttachment(fe.target?.files?.[0]!);
              }}
            />
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Logo
              </Typography>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                spacing="20px"
                alignItems={"center"}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: "64px", height: "64px" }}
                    src={uploadInfo}
                  >
                    <IC_ADD_IMAGE />
                  </Avatar>
                  {uploadState === "uploading" && (
                    <CircularProgress
                      variant="determinate"
                      value={uploadProgress}
                      size="68px"
                      sx={{ position: "absolute" }}
                    />
                  )}
                </div>
                <Stack
                  onClick={(c) => {
                    if (busy) return;
                    document.getElementById("file-upload")?.click();
                  }}
                  flex={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    background: "#FFFFFF13",
                    p: "16px",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  <Typography fontSize={14} fontWeight={600} color="#CECFD2">
                    Click to upload
                  </Typography>
                  <Typography fontSize={12} fontWeight={400} color="#94969C">
                    PNG, JPG
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={createProject}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
            >
              Create Project
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
